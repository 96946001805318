import UrlMethodsMixin from "@/mixins/UrlMethodsMixin";

export default {
    mixins: [
        UrlMethodsMixin
    ],
    data() {
        return {
            activeSetting: '',
            dateToday: Math.round((((new Date()).getTime()) / 100000000)),
            activeFilters: [],
            openFacetGroups: [],
            convertedFeatures: [],
            isFacetTitleVisible: false,
            interval: '',
            filterItems: []
        };
    },
    computed: {
        filterSettings() {
            return this.$store.getters.getFilter
        },
        filteredFeatures() {
            return this.$store.getters.getFilteredFeatures
        },
        features() {
            return this.$store.getters.getFeatures
        },
        searchValue() {
            return this.$store.getters.getSearchValue
        },
        geoLocation() {
            return this.$store.getters.getGeoLocation
        },
        getFilterItems() {
            return this.$store.getters.getFilterItems
        },
        getDependentFilter() {
            return this.$store.getters.getDependentFilter
        }
    },
    watch: {
        searchValue(searchValue) {

            if(searchValue === undefined) {
                return;
            }

            this.asyncCall(null, null, null, null).then(() => {
                console.log('result')
            })

            this.$store.commit('setActivePage', 0);
            this.$store.commit('setUrlPage', 1)

            // console.log('test')
            this.convertedFeatures = [];
            this.filterSettings.records[0].attributes.map(setting => {
                this.generateConvertedFeatures(setting[0]);
            });
        },
        geoLocation(geoLocation) {
            // console.log('geoLocation')

            if(geoLocation === undefined) {
                return
            }

            if(geoLocation === null) {
                this.activeFilters = this.activeFilters.filter( activeFilter => activeFilter.filter.Type !== 21)
            }

            this.asyncCall(null, null, null, null).then(() => {
                console.log('result')
            })

            this.$store.commit('setActivePage', 0);
            this.$store.commit('setUrlPage', 1)

            this.convertedFeatures = [];
            this.filterSettings.records[0].attributes.map(setting => {
                this.generateConvertedFeatures(setting[0]);
            });
        }
    },
    created() {

        // console.log('created')
        const interval = setInterval(() => {

            const filterSettings = this.filterSettings

            // console.log(filterSettings)

            if(filterSettings !== null && filterSettings !== '' && filterSettings.records !== null && this.features.length > 0) {

                filterSettings.records[0].attributes.map(setting => {
                    this.generateConvertedFeatures(setting[0]);
                });

                filterSettings.records[0].attributes.map((value, index) => {
                    if(value[0].Folded === undefined || parseInt(value[0].Folded) === 0) {
                        this.openFacetGroups.push(index);
                    }
                });

                clearInterval(interval)
            }
        }, 300);
    },
    methods: {
        activeFilterCheckedClass(activeFilterSetting, formAttributeID) {
            const result = this.activeFilterClass(activeFilterSetting, formAttributeID)

            // console.log(activeFilterSetting)
            // console.log(result)

            if(result === '') {
                return ''
            }

            return 'checked'
        },
        activeFilterClass(activeFilterSetting, formAttributeID, settingTypeID) {

            if(
                activeFilterSetting === undefined) {
                return '';
            }

            if(activeFilterSetting !== null) {
                activeFilterSetting = activeFilterSetting.toString()
            }

            if(this.activeSetting !== null) {
                this.activeSetting = this.activeSetting.toString()
            }

            if(this.getDependentFilter === 1) {

                let isActive = ''
                this.activeFilters.map((activeFilter) => {

                    if(activeFilter.filter !== undefined && activeFilter.filter.ID === formAttributeID) {

                        if(settingTypeID === 21) {

                            console.log(activeFilter.value.name)

                            if(activeFilter.value.name === activeFilterSetting) {
                                isActive = 'active'
                            }
                        } else {

                            if (activeFilter.value === null) {
                                if (activeFilterSetting === null) {
                                    isActive = 'active'
                                }
                            } else if (activeFilter.value.toString() === activeFilterSetting) {
                                isActive = 'active'
                            }
                        }
                    }
                });

                if(isActive !== '') {
                    return isActive
                }
            } else {
                // console.log(activeFilterSetting)
                // console.log(this.activeSetting)
                if(this.activeFilters.length > 0 && this.activeFilters[0].filter !== undefined && this.activeFilters[0].filter.ID === formAttributeID) {
                    if (this.activeSetting === activeFilterSetting) {
                        return 'active'
                    }
                }
            }

            return ''
        },
        getFacetTileAriaLabel(index) {
            if(this.openFacetGroups.includes(index)) {
                return 'Klap dicht'
            }
            return 'Klap open'
        },
        toggleFacetGroup(event, key) {

            if (event) {
                event.preventDefault()
            }

            if(this.openFacetGroups.includes(key)) {
                this.openFacetGroups.splice(this.openFacetGroups.indexOf(key), 1);
            } else {
                this.openFacetGroups.push(key);
            }
        },
        toggleFacetTitle() {
            //console.log('click')
            this.isFacetTitleVisible = !this.isFacetTitleVisible;
        },
        isGeometryLoaded(setting) {
            if(setting[0].Type === 21 && this.geoLocation === null) {
                return false
            }
            return true
        },
        isExpanded(key) {
            if(this.openFacetGroups.includes(key)) {
                return 'expanded'
            }
            return '';
        },
        isAriaExpanded(key) {
            if(this.openFacetGroups.includes(key)) {
                return 'true'
            }
            return 'false';
        },
        resetFilters() {
            this.activeFilters = []
            this.$store.commit('setFilteredFeatures', this.features)
        },
        checkFeatureProperties(feature, searchValue) {

            this.$store.commit('setActiveFeature', {});

            for (const key in feature.properties) {
                const obj = feature.properties[key];
                if (obj !== null) {
                    if (obj.toString().toLowerCase().includes(searchValue.toLowerCase())) {
                        return true;
                    }
                }
            }
            return false;
        },
        filterItemIsMultiSelect(filterTypeID) {
             if(filterTypeID === 1023) {
                 return true;
             }
             return false;
        },
        filter(e, setting, value, name, layout = '') {

            // console.log('filterrr')

            if(this.getDependentFilter === 1) {
                //todo
            } else if(!this.filterItemIsMultiSelect(setting.MutationTypeID)) {
                this.activeFilters = [];
            }

            e.preventDefault();

            // console.log('filter')

            this.$store.commit('setActiveSubTab', '')

            if(layout === 'Nijmegen') {
                // console.log(e)
                // console.log(e.originalTarget.id)
                // document.getElementById('facet-item-checkbox-HIS_MON_MONUMENT.2701').checked = true
            }

            this.asyncCall(e, setting, value, name).then(() => {
                console.log('result')
            })
        },
        async asyncCall(e, setting, value, name) {
            await this.resolveAfter2Seconds(e, setting, value, name);
        },
        resolveAfter2Seconds(e, setting, value, name) {

            console.log('resolveAfter2Seconds')
            console.log(setting)
            console.log(value)
            console.log(name)

            this.$store.commit('setUrlFacetValue', value)
            this.$store.commit('setUrlFacetName', name)

            let activeFilter = {};

            let filteredFeatures = [];
            if (setting !== null && !this.filterItemIsMultiSelect(setting.MutationTypeID) && this.getDependentFilter === 0 && (this.activeSetting === value || this.activeSetting === value.name )) {
                this.activeSetting = {};
                filteredFeatures = this.features

            } else {

                    this.activeSetting = value

                    activeFilter = {};
                    activeFilter.name = name;
                    activeFilter.value = value;
                    activeFilter.filter = setting;

                    console.log(this.activeFilters)

                    // Filter is multiselect
                    if( (setting !== null && this.filterItemIsMultiSelect(setting.MutationTypeID)) || this.getDependentFilter === 1) {

                        if(activeFilter.filter !== null) {
                            let activeFilterExists = this.activeFilters.find(activeFilter =>
                                activeFilter.name === name && activeFilter.value === value
                            );

                            if (parseInt(activeFilter.filter.Type) === 21) {
                                activeFilterExists = this.activeFilters.find(activeFilter => activeFilter.filter === setting);
                                if (activeFilterExists !== undefined) {
                                    this.activeFilters.splice(this.activeFilters.indexOf(activeFilterExists), 1);
                                    this.activeFilters.push(activeFilter);
                                } else {
                                    this.activeFilters.push(activeFilter);
                                }
                            } else {

                                // console.log(activeFilterExists)
                                //console.log(this.activeFilters.length)
                                if (activeFilterExists !== undefined) {
                                    // this.activeFilters = this.activeFilters.filter(
                                    //     activeFilter => activeFilter.name === name && activeFilter.value !== value
                                    // );
                                    this.activeFilters.splice(this.activeFilters.indexOf(activeFilterExists), 1);
                                } else {
                                    this.activeFilters.push(activeFilter);
                                }
                            }
                        }

                        //console.log(this.activeFilters.length)

                        // //console.log(activeFilterExists)
                        // //console.log(this.activeFilters)

                        // Clear all features is there are no filters anymore
                        if(this.activeFilters.length === 0) {

                            this.features.map((feature) => {
                                let searchValueFound = false
                                for (const key in feature.properties) {
                                    const obj = feature.properties[key];
                                    if (obj !== null) {
                                        if (obj.toString().toLowerCase().includes(this.searchValue.toLowerCase())) {
                                            searchValueFound = true;
                                        }
                                    }
                                }

                                if(searchValueFound) {
                                    filteredFeatures.push(feature);
                                }
                            });
                        } else {

                            this.features.map((feature) => {

                                let exists = this.activeFilters.length


                                this.activeFilters.map((activeFilter) => {



                                    let totalFilters = this.activeFilters.filter(
                                        (activeFilterCounter) => {
                                       return activeFilter.filter.ID === activeFilterCounter.filter.ID
                                    }).length;



                                    if(parseInt(activeFilter.filter.Type) === 21) {
                                        if (feature.geometry !== null && activeFilter.value.distance >= feature.properties.kv.geometry.value) {
                                            // filteredFeatures.push(feature);
                                        // }
                                        // if(feature.properties.kv.geometry.name === activeFilter.value){
                                            if(totalFilters === 0) {
                                                totalFilters = 1
                                            }

                                            exists = exists - totalFilters;
                                        }
                                    } else {
                                        if (feature.properties[activeFilter.filter.Attribute] === activeFilter.value) {

                                            if(totalFilters === 0) {
                                                totalFilters = 1
                                            }

                                            exists = exists - totalFilters;
                                        }
                                    }



                                });

                                // console.log(exists)

                                if(exists <= 0) {

                                    if(this.searchValue !== '') {
                                        let searchValueFound = false
                                        for (const key in feature.properties) {
                                            const obj = feature.properties[key];
                                            if (obj !== null) {
                                                if (obj.toString().toLowerCase().includes(this.searchValue.toLowerCase())) {
                                                    searchValueFound = true;
                                                }
                                            }
                                        }

                                        if(searchValueFound) {
                                            filteredFeatures.push(feature);
                                        }
                                    } else {
                                        filteredFeatures.push(feature);
                                    }
                                }
                            });
                        }

                    } else {
                        const facets = document.getElementsByClassName('facets__checkbox')
                        for (var i = 0; i < facets.length; i++) {
                            facets.item(i).checked = false
                        }

                        this.features.map((feature) => {
                            if (feature.properties[setting.Attribute] === value) {
                                filteredFeatures.push(feature);
                            }
                        });

                        this.activeFilters.push(activeFilter);
                    }
                }
            // }

            // console.log(this.activeFilters)
            // console.log(this.activeSetting)

            // //console.log(this.activeFilters)

            console.log(filteredFeatures)

            this.$store.commit('setActivePage', 0);
            this.$store.commit('setUrlPage', 1)
            this.$store.commit('setFilteredFeatures', filteredFeatures)

            this.convertedFeatures = []
            this.filterSettings.records[0].attributes.map(setting => {
               this.generateConvertedFeatures(setting[0]);
            });
        },
        // convertFeatures(setting) {
        //
        //     //console.log(setting.ID)
        //
        //     console.log('reloadd')
        //
        //     //console.log(this.convertedFeatures)
        //     if(this.convertedFeatures.length === 0) {
        //
        //         setTimeout(() => {
        //             // this.generateConvertedFeatures(setting)
        //             this.convertFeatures(setting)
        //         }, 1000);
        //
        //         return  []
        //     }
        //
        //     let result =  this.convertedFeatures.find(
        //         convertedFeature => convertedFeature.ID === setting.ID
        //     ).features;
        //
        //     console.log(result)
        //
        //     return result
        //
        // },
        generateConvertedFeatures(setting) {

            // console.log('convert features')

            const convertedFeatures = [];

            let features = this.features;

            let feature = {};
            const dateToday = Math.round((((new Date()).getTime()) / 100000000));

            if(parseInt(setting.Type) === 21) {
                feature = {};
                feature.counter = 0;
                feature.properties = {};
                feature.properties.kv = {};
                feature.properties.kv[setting.Attribute] = {}
                feature.properties.kv[setting.Attribute].name = 'Binnen 50 km';
                feature.properties.kv[setting.Attribute].distance = 50000;
                convertedFeatures.push(feature);
                feature = {};
                feature.counter = 0;
                feature.properties = {};
                feature.properties.kv = {};
                feature.properties.kv[setting.Attribute] = {}
                feature.properties.kv[setting.Attribute].name = 'Binnen 10 km';
                feature.properties.kv[setting.Attribute].distance = 10000;
                convertedFeatures.push(feature);
                feature = {};
                feature.counter = 0;
                feature.properties = {};
                feature.properties.kv = {};
                feature.properties.kv[setting.Attribute] = {}
                feature.properties.kv[setting.Attribute].name = 'Binnen 5 km';
                feature.properties.kv[setting.Attribute].distance = 5000;
                convertedFeatures.push(feature);
                feature = {};
                feature.counter = 0;
                feature.properties = {};
                feature.properties.kv = {};
                feature.properties.kv[setting.Attribute] = {}
                feature.properties.kv[setting.Attribute].name = 'Binnen 2 km';
                feature.properties.kv[setting.Attribute].distance = 2000;
                convertedFeatures.push(feature);
                feature = {};
                feature.counter = 0;
                feature.properties = {};
                feature.properties.kv = {};
                feature.properties.kv[setting.Attribute] = {}
                feature.properties.kv[setting.Attribute].name = 'Binnen 1 km';
                feature.properties.kv[setting.Attribute].distance = 1000;
                convertedFeatures.push(feature);
                feature = {};
                feature.counter = 0;
                feature.properties = {};
                feature.properties.kv = {};
                feature.properties.kv[setting.Attribute] = {}
                feature.properties.kv[setting.Attribute].name = 'Binnen 500 m';
                feature.properties.kv[setting.Attribute].distance = 500;
                convertedFeatures.push(feature);
                feature = {};
                feature.counter = 0;
                feature.properties = {};
                feature.properties.kv = {};
                feature.properties.kv[setting.Attribute] = {}
                feature.properties.kv[setting.Attribute].name = 'Binnen 200 m';
                feature.properties.kv[setting.Attribute].distance = 200;
                convertedFeatures.push(feature);
            }

            switch (parseInt(setting.Type)) {
                // Text
                case 0:
                case 21:
                    if(this.getDependentFilter === 0) {

                        // console.log(this.activeFilters)

                        features.map((feature) => {

                            // console.log(feature)

                            const convertedFeature = convertedFeatures.find(convertedFeature => convertedFeature.properties[setting.Attribute] === feature.properties[setting.Attribute]);

                            // console.log(convertedFeature)

                            if (convertedFeature === undefined) {
                                feature.counter = 1;
                                convertedFeatures.push(Object.assign({}, feature));
                            } else {
                                convertedFeature.counter++;
                            }

                        });

                    } else {




                        let uniqueFilters = []
                        const searchValue = this.searchValue
                        let searchValueFound = false
                        const activeFilters = this.activeFilters

                        activeFilters.map((activeFilter) => {
                            const uniqueFilterCheck = uniqueFilters.find( uniqueFilterName => uniqueFilterName === activeFilter.filter.ID)

                            if(uniqueFilterCheck === undefined) {
                                uniqueFilters.push(activeFilter.filter.ID)
                            }
                        })

                        // console.log(activeFilters)

                        features.map((feature) => {

                            let convertedFeature = convertedFeatures.find(convertedFeature => convertedFeature.properties[setting.Attribute] === feature.properties[setting.Attribute]);
                            if (convertedFeature === undefined) {
                                feature.counter = 0;
                                convertedFeatures.push(Object.assign({}, feature));

                                convertedFeature = convertedFeatures.find(convertedFeature => convertedFeature.properties[setting.Attribute] === feature.properties[setting.Attribute]);
                            }

                            let found = uniqueFilters.length
                            if(activeFilters.length === 0) {
                                found = 0
                            }

                            activeFilters.map((activeFilter) => {

                                if(parseInt(activeFilter.filter.Type) === 21) {
                                    if (activeFilter.filter.ID === setting.ID) {
                                        if(uniqueFilters.length === 1) {
                                            found = found - uniqueFilters.length
                                        } else {
                                            found = found - 1
                                        }
                                    } else if(feature.geometry !== null && feature.properties.kv.geometry !== undefined && activeFilter.value.distance >= feature.properties.kv.geometry.value){
                                        // console.log(feature.properties.kv.geometry.name)
                                        found = found - 1
                                    }
                                } else {
                                    if (activeFilter.filter.ID === setting.ID) {
                                        if(uniqueFilters.length === 1) {
                                            found = found - uniqueFilters.length
                                        } else {

                                            if(activeFilters.filter( activeFilterCheck => activeFilterCheck.filter.ID === activeFilter.filter.ID).length > 1) {
                                                found = found - 0.5
                                            } else {
                                                found = found - 1
                                            }
                                        }
                                    } else if(feature.properties[activeFilter.filter.Attribute] === activeFilter.value) {
                                        found = found - 1
                                    }
                                }
                            })

                            // Check search value
                            searchValueFound = true
                            if(searchValue !== '') {
                                searchValueFound = false
                                for (const key in feature.properties) {
                                    const obj = feature.properties[key];
                                    if (obj !== null) {
                                        if (obj.toString().toLowerCase().includes(searchValue.toLowerCase())) {
                                            searchValueFound = true;
                                        }
                                    }
                                }

                                // if(!searchValueFound) {
                                //     found++
                                // }
                            }

                            if(found <= 0 && searchValueFound) {

                                if(parseInt(setting.Type) === 21) {
                                    if (feature.geometry !== null && this.geoLocation !== null) {
                                        const gpsX = this.geoLocation.lng;
                                        const gpsY = this.geoLocation.lat;

                                        let objX = null;
                                        let objY = null;

                                        const newCoordinates = [];

                                        switch (feature.geometry.type) {
                                            case 'Point':
                                                objX = feature.geometry.coordinates[0];
                                                objY = feature.geometry.coordinates[1];
                                                break;
                                            case 'Polygon':
                                                feature.geometry.coordinates[0].map( (coordinate) => {
                                                    newCoordinates.push([coordinate[1], coordinate[0]]);
                                                });
                                                objX = window.L.latLngBounds(newCoordinates).getCenter().lng;
                                                objY = window.L.latLngBounds(newCoordinates).getCenter().lat;
                                                break;
                                            case 'LineString':
                                                objX = feature.geometry.coordinates[0][0]
                                                objY = feature.geometry.coordinates[0][1];
                                                break;
                                            case 'MultiPolygon':
                                                objX = feature.geometry.coordinates[0][0][0][0]
                                                objY = feature.geometry.coordinates[0][0][0][1]
                                                break;
                                        }

                                        if(objX !== null && objY !== null) {
                                            const distance = this.calculateDistance([gpsX, gpsY], [objX, objY]);

                                            convertedFeatures.map((convertedFeature) => {
                                                if (distance <= convertedFeature.properties.kv[setting.Attribute].distance) {
                                                    convertedFeature.counter++;
                                                }
                                            });
                                        }
                                    }

                                } else {
                                    convertedFeature.counter++;
                                }

                            }
                        });
                    }

                    // console.log(convertedFeatures)
                    break;
                // Geometry
                // case 21:
                //     break;
                // // Date
                case 402:
                case 403:

                    feature = {};
                    feature.counter = 0;
                    feature.properties = {};
                    feature.properties.kv = {};
                    feature.properties.kv[setting.Attribute] = {}
                    feature.properties.kv[setting.Attribute].name = 'Afgelopen week';
                    feature.properties.kv[setting.Attribute].distance = -6;
                    convertedFeatures.push(feature);
                    feature = {};
                    feature.counter = 0;
                    feature.properties = {};
                    feature.properties.kv = {};
                    feature.properties.kv[setting.Attribute] = {}
                    feature.properties.kv[setting.Attribute].name = 'Afgelopen maand';
                    feature.properties.kv[setting.Attribute].distance = -30;
                    convertedFeatures.push(feature);
                    feature = {};
                    feature.counter = 0;
                    feature.properties = {};
                    feature.properties.kv = {};
                    feature.properties.kv[setting.Attribute] = {}
                    feature.properties.kv[setting.Attribute].name = 'Vandaag';
                    feature.properties.kv[setting.Attribute].distance = 0;
                    convertedFeatures.push(feature);
                    feature = {};
                    feature.counter = 0;
                    feature.properties = {};
                    feature.properties.kv = {};
                    feature.properties.kv[setting.Attribute] = {}
                    feature.properties.kv[setting.Attribute].name = 'Deze week';
                    feature.properties.kv[setting.Attribute].distance = 6;
                    convertedFeatures.push(feature);
                    feature = {};
                    feature.counter = 0;
                    feature.properties = {};
                    feature.properties.kv = {};
                    feature.properties.kv[setting.Attribute] = {}
                    feature.properties.kv[setting.Attribute].name = 'Deze maand';
                    feature.properties.kv[setting.Attribute].distance = 30;
                    convertedFeatures.push(feature);
                    feature = {};
                    feature.counter = 0;
                    feature.properties = {};
                    feature.properties.kv = {};
                    feature.properties.kv[setting.Attribute] = {}
                    feature.properties.kv[setting.Attribute].name = 'Dit jaar';
                    feature.properties.kv[setting.Attribute].distance = 365;
                    convertedFeatures.push(feature);

                    features.map((feature) => {
                        convertedFeatures.map((convertedFeature) => {

                            //console.log(feature.properties.kv)

                            if(feature.properties.kv[setting.Attribute] !== undefined) {
                                const dateProperty = feature.properties.kv[setting.Attribute].value

                                const distance = convertedFeature.properties.kv[setting.Attribute].distance;
                                const dateCategory = Math.round((((new Date()).getTime() + (86400000 * distance)) / 100000000));

                                if (Math.sign(distance) === -1) {
                                    if (dateProperty <= dateToday && dateProperty >= dateCategory) {
                                        convertedFeature.counter++;
                                    }
                                } else {
                                    if (dateProperty >= dateToday && dateProperty <= dateCategory) {
                                        convertedFeature.counter++;
                                    }
                                }
                            }
                        });
                    });
                    break;
            }

            console.log('sorttt2')

            console.log(setting)

            convertedFeatures.sort(function(a, b){
                if(a.properties[setting.Attribute] < b.properties[setting.Attribute]) {
                    return -1;
                }
                if(a.properties[setting.Attribute] > b.properties[setting.Attribute]) {
                    return 1;
                }
                return 0;
            })

            this.convertedFeatures.push({
                ID: setting.ID,
                features: convertedFeatures
            });

            // console.log('setFilterItems')
            this.$store.commit('setFilterItems', this.convertedFeatures)

            setTimeout(function () {
                let checked

                if(this.convertedFeatures !== undefined) {
                    this.convertedFeatures.map((convertedFeature) => {

                        convertedFeature.features.map(feature => {
                            checked = false

                            this.activeFilters.map(activeFilter => {
                                if (activeFilter.filter !== undefined && activeFilter.value === feature.properties[activeFilter.filter.Attribute]) {
                                    checked = true
                                }
                            });

                            const element = document.getElementById('facet-item-checkbox-' + feature.id)

                            if(element !== null) {
                                element.checked = checked
                            }
                        })
                    })
                }
            }.bind(this), 0);
        },
        toRadian(degree) {
            return degree * Math.PI / 180;
        },
        calculateDistance(origin, destination) {
            // return distance in meters
            var lon1 = this.toRadian(origin[1]),
                lat1 = this.toRadian(origin[0]),
                lon2 = this.toRadian(destination[1]),
                lat2 = this.toRadian(destination[0]);

            var deltaLat = lat2 - lat1;
            var deltaLon = lon2 - lon1;

            var a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
            var c = 2 * Math.asin(Math.sqrt(a));
            var EARTH_RADIUS = 6371;
            // return c * EARTH_RADIUS * 1000;
            return c * EARTH_RADIUS * 1000;
        },
    }
}
