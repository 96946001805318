import UrlMethodsMixin from "@/mixins/UrlMethodsMixin";

export default {
    mixins: [
        UrlMethodsMixin
    ],
    computed: {
        getUrlQ() {
            return this.$store.getters.getUrlQ
        },
        getUrlPage() {
            return this.$store.getters.getUrlPage
        },
        getUrlFacetName() {
            return this.$store.getters.getUrlFacetName
        },
        getUrlFacetValue() {
            return this.$store.getters.getUrlFaceValue
        },
        getActiveFeature() {
            return this.$store.getters.getActiveFeature
        }
    },
    watch: {
        getUrlQ() {

            this.updateUrl()
        },
        getUrlPage() {

            this.updateUrl()
        },
        getUrlFacetName() {

            this.updateUrl()
        },
        getUrlFacetValue() {

            this.updateUrl()
        },
        getActiveFeature() {

            this.updateUrl()
        }
    }
}
