import Vue from 'vue'
import App from './App.vue'
import store from './store'
import {Icon} from 'leaflet';
import VueGeolocation from 'vue-browser-geolocation';
import Autocomplete from '@trevoreyre/autocomplete-vue'
// import '@trevoreyre/autocomplete-vue/dist/style.css'
import fullscreen from 'vue-fullscreen'
import Lightbox from 'vue-easy-lightbox'

Vue.use(Lightbox)
Vue.use(fullscreen)
Vue.use(VueGeolocation);
Vue.use(Autocomplete)
Vue.config.productionTip = false

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
    store,
    render(h) {
        return h(App, {
            props: {
                kaartViewerUrl: this.$el.getAttribute('data-kaart-viewer-url'),
                bookmarkName: this.$el.getAttribute('data-bookmark-name'),
                bookmarkGroupName: this.$el.getAttribute('data-bookmark-group-name'),
                clusterZoom: this.$el.getAttribute('data-cluster-zoom'),
                layoutCombi: this.$el.getAttribute('data-show-combi'),
                splitLeft: this.$el.getAttribute('data-split-left'),
                splitRight: this.$el.getAttribute('data-split-right'),
                hideSearch: this.$el.getAttribute('data-hide-search'),
                hideLocationSearch: this.$el.getAttribute('data-hide-location-search'),
                zoomToFilterResult: this.$el.getAttribute('data-zoom-to-filter-result'),
                mapFirst: this.$el.getAttribute('data-map-first'),
                dependentFilter: this.$el.getAttribute('data-dependent-filter'),
                updateUrlParams: this.$el.getAttribute('data-update-url-params'),
            }
        })
    },
}).$mount('#geocomponent')
