import RouteService from "@/services/route.service";
import UrlMethodsMixin from "@/mixins/UrlMethodsMixin";

export default {
    mixins: [
        UrlMethodsMixin
    ],
    data() {
        return {
            defaultValue: 'Test',
            searchLocationValue: '',
            keyUpLocationValue: '',
            filteredFeatureCopy: [],
            listItemActive: 0
        }
    },
    watch: {
        searchValueLocation(searchValueLocation) {
            if(searchValueLocation === '') {
                this.$refs.autocompleteLocation.setValue('')
            }
        }
    },
    computed: {
        kaartViewerUrl() {
            return this.$store.getters.getKaartViewerUrl
        },
        searchValueLocation() {
            return this.$store.getters.getSearchValueLocation
        }
    },
    methods: {
        handleFormSubmit(event) {

            console.log('clear')

            event.preventDefault()
        },
        askForLocation(e) {
            e.preventDefault();
            this.$getLocation({enableHighAccuracy: true}).then(position => {
                position.type = 'gps';
                this.$store.commit('setGeoLocation', position);

                RouteService.getLocationReversed(this.kaartViewerUrl, position.lng, position.lat).then( (result) => {

                    const resultValue = result.data.straat + ' ' + result.data.huisnummer
                    this.setValue(resultValue)
                    this.keyUpLocationValue = resultValue

                    this.filteredFeatureCopy = this.filteredFeatures
                    this.filteredFeatureCopy.map(feature => {
                          this.addDistance(feature, position)
                    });


                    this.$store.commit('setFilteredFeatures', this.filteredFeatureCopy)
                });
            }, () => {

            }).finally(() => {

            });
        },
        searchForLocation(result) {
            RouteService.lookup(this.kaartViewerUrl, result.data.id).then( (result) => {
                const feature = result.data.response.docs[0];

                const geometry = feature.centroide_ll.replace('POINT(', '').replace(')', '').split(' ')

                const position = {}
                position.type = 'gps';
                position.lat = parseFloat(geometry[1]);
                position.lng = parseFloat(geometry[0]);

                this.filteredFeatureCopy = this.features
                this.filteredFeatureCopy.map(feature => {
                    this.addDistance(feature, position)
                });

                // this.$store.commit('setSearchValue', '')
                this.$store.commit('setGeoLocation', position);
                // this.$store.commit('setFilteredFeatures', this.features)

            });
        },
        addDistance(feature, position) {

            if (feature.geometry !== null && position !== null) {

                const geometryName = 'geometry'
                const gpsX = position.lng;
                const gpsY = position.lat;

                let objX;
                let objY;

                const newCoordinates = [];
                switch (feature.geometry.type) {
                    case 'Point':
                        objX = feature.geometry.coordinates[0];
                        objY = feature.geometry.coordinates[1];
                        break;
                    case 'Polygon':
                        feature.geometry.coordinates[0].map( (coordinate) => {
                            newCoordinates.push([coordinate[1], coordinate[0]]);
                        });
                        objX = window.L.latLngBounds(newCoordinates).getCenter().lng;
                        objY = window.L.latLngBounds(newCoordinates).getCenter().lat;
                        break;
                    case 'LineString':
                        objX = feature.geometry.coordinates[0][0]
                        objY = feature.geometry.coordinates[0][1];
                        break;
                    case 'MultiPolygon':
                        // feature.geometry.coordinates[0][0].map( (coordinate) => {
                        //     newCoordinates.push([coordinate[1], coordinate[0]]);
                        // });
                        // objX = window.L.latLngBounds(newCoordinates).getCenter().lng;
                        // objY = window.L.latLngBounds(newCoordinates).getCenter().lat;

                        objX = feature.geometry.coordinates[0][0][0][0]
                        objY = feature.geometry.coordinates[0][0][0][1]
                        break;
                }

                const distance = this.calculateDistance([gpsX, gpsY], [objX, objY]);

                feature.properties.kv = {};
                feature.properties.kv[geometryName] = {}
                feature.properties.kv[geometryName].value = distance

                if (distance <= 50000) {
                    feature.properties.kv[geometryName].name = 'Binnen 50 km';
                }
                if (distance <= 10000) {
                    feature.properties.kv[geometryName].name = 'Binnen 10 km';
                }
                if (distance <= 5000) {
                    feature.properties.kv[geometryName].name = 'Binnen 5 km';
                }
                if (distance <= 2000) {
                    feature.properties.kv[geometryName].name = 'Binnen 2 km';
                }
                if (distance <= 1000) {
                    feature.properties.kv[geometryName].name = 'Binnen 1 km';
                }
                if (distance <= 500) {
                    feature.properties.kv[geometryName].name = 'Binnen 500 m';
                }
                if (distance <= 200) {
                    feature.properties.kv[geometryName].name = 'Binnen 200 m';
                }
            } else {
                feature.properties.kv = {};
            }

            return feature
        },
        calculateDistance(origin, destination) {
            // return distance in meters
            var lon1 = this.toRadian(origin[1]),
                lat1 = this.toRadian(origin[0]),
                lon2 = this.toRadian(destination[1]),
                lat2 = this.toRadian(destination[0]);

            var deltaLat = lat2 - lat1;
            var deltaLon = lon2 - lon1;

            var a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
            var c = 2 * Math.asin(Math.sqrt(a));
            var EARTH_RADIUS = 6371;
            // return c * EARTH_RADIUS * 1000;
            return c * EARTH_RADIUS * 1000;
        },
        toRadian(degree) {
            return degree * Math.PI / 180;
        },
    }
}
