export const setting = {
    state: {
        previousActiveTab: 'list',
        activeTab: '',
        activeSubTab: '',
        settings: {},
        searchValue: '',
        list: {},
        map: {},
        detail: {},
        details: [],
        filter: {},
        geoLocation: null,
        kaartViewerUrl: '',
        bookmarkName: '',
        bookmarkGroupName: '',
        activePage: 0,
        layoutCombi: 0,
        splitLeft: 3,
        splitRight: 9,
        presentationsLinked: [],
        hideSearch: 0,
        hideLocationSearch: 0,
        fullScreen: false,
        zoomToFilterResult: 0,
        mapFirst: 0,
        dependentFilter: 1,
        updateUrlParams: 1,
        searchValueLocation: '',
        showFilteredFeatures: true
    },
    getters: {
        getPreviousActiveTab: state => {
            return state.previousActiveTab
        },
        getShowFilteredFeatures: state => {
            return state.showFilteredFeatures
        },
        getActiveTab: state => {
            return state.activeTab
        },
        getActiveSubTab: state => {
            return state.activeSubTab
        },
        getSettings: state => {
            return state.settings
        },
        getMap: state => {
            return state.map
        },
        getList: state => {
            return state.list
        },
        getDetail: state => {
            return state.detail
        },
        getDetails: state => {
            return state.details
        },
        getFilter: state => {
            return state.filter
        },
        getSearchValue: state => {
            return state.searchValue
        },
        getGeoLocation: state => {
            return state.geoLocation
        },
        getKaartViewerUrl: state => {
            return state.kaartViewerUrl
        },
        getBookmarkName: state => {
            return state.bookmarkName
        },
        getBookmarkGroupName: state => {
            return state.bookmarkGroupName
        },
        getActivePage: state => {
            return state.activePage
        },
        getLayoutCombi: state => {
            return state.layoutCombi
        },
        getSplitLeft: state => {
            return state.splitLeft
        },
        getSplitRight: state => {
            return state.splitRight
        },
        getPresentationsLinked: state => {
            return state.presentationsLinked
        },
        getHideSearch: state => {
            return state.hideSearch
        },
        getHideLocationSearch: state => {
            return state.hideLocationSearch
        },
        getFullScreen: state => {
            return state.fullScreen
        },
        getZoomToFilterResult: state => {
            return state.zoomToFilterResult
        },
        getMapFirst: state => {
            return state.mapFirst
        },
        getDependentFilter: state => {
            return state.dependentFilter
        },
        getUpdateUrlParams: state => {
            return state.updateUrlParams
        },
        getSearchValueLocation: state => {
            return state.searchValueLocation
        }
    },
    mutations: {
        setPreviousActiveTab(state, previousActiveTab) {
            state.previousActiveTab = previousActiveTab
        },
        setActiveTab(state, activeTab) {
            state.activeTab = activeTab
        },
        setActiveSubTab(state, activeTab) {
            state.activeSubTab = activeTab
        },
        setSettings(state, settings) {
            state.settings = settings
        },
        setMap(state, map) {
            state.map = map
        },
        setList(state, list) {
            state.list = list
        },
        setDetail(state, detail) {
            state.detail = detail
        },
        setDetails(state, details) {
            state.details = details
        },
        setFilter(state, filter) {
            state.filter = filter
        },
        setSearchValue(state, searchValue) {
            state.searchValue = searchValue
        },
        setGeoLocation(state, geoLocation) {
            state.geoLocation = geoLocation
        },
        setKaartViewerUrl(state, kaartViewerUrl) {
            state.kaartViewerUrl = kaartViewerUrl
        },
        setBookmarkName(state, bookmarkName) {
            state.bookmarkName = bookmarkName
        },
        setBookmarkGroupName(state, bookmarkGroupName) {
            state.bookmarkGroupName = bookmarkGroupName
        },
        setActivePage(state, activePage) {
            state.activePage = activePage
        },
        setLayoutCombi(state, layoutCombi) {
            state.layoutCombi = parseInt(layoutCombi)
        },
        setSplitRight(state, splitRight) {
            state.splitRight = splitRight
        },
        setSplitLeft(state, splitLeft) {
            state.splitLeft = splitLeft
        },
        setPresentationsLinked(state, presentationsLinked) {
            state.presentationsLinked = presentationsLinked
        },
        setHideSearch(state, hideSearch) {
            state.hideSearch = hideSearch
        },
        setHideLocationSearch(state, hideLocationSearch) {
            state.hideLocationSearch = hideLocationSearch
        },
        setFullScreen(state, fullScreen) {
            state.fullScreen = fullScreen
        },
        setZoomToFilterResult(state, zoomToFilterResult) {
            state.zoomToFilterResult = zoomToFilterResult
        },
        setMapFirst(state, mapFirst) {
            state.mapFirst = mapFirst
        },
        setDependentFilter(state, dependentFilter) {
            state.dependentFilter = dependentFilter
        },
        setUpdateUrlParams(state, updateUrlParams) {
            state.updateUrlParams = updateUrlParams
        },
        setSearchValueLocation(state, searchValueLocation) {
            state.searchValueLocation = searchValueLocation
        },
        setShowFilteredFeatures(state, showFilteredFeatures) {
            state.showFilteredFeatures = showFilteredFeatures
        }
    }
}
