
<template>

  <div role="search" @submit="handleFormSubmit">
    <autocomplete ref="autocompleteSearch" :search="search" @submit="onSubmit">
<!--      <input v-model="searchValue" type="text" id="gn-filterinput" placeholder="Verfijn hier je resultaat" name="q" />-->

      <template
          #default="{
        rootProps,
        inputProps,
        inputListeners,
        resultListProps,
        resultListListeners,
        results,
        resultProps
      }"
      >
        <div class="autocomplete autocomplete__form" v-bind="rootProps">
          <label for="gn-filterinput" class="gn-sr-only">Verfijn je resultaat</label>
          <input
              class="gn-filterinput"
              type="text"
              v-bind="inputProps"
              v-on="inputListeners"
              name="q"
              id="gn-filterinput"
              placeholder="Zoek in de lijst"
              autocomplete="off"
              aria-controls="autocomplete-results"
              aria-autocomplete="both"
              aria-label="Zoekveld"
              aria-describedby="autocomplete-help-text"
              ref="searchInput"
              @keyup="onkeyup"
          />

          <button v-if="keyUpValue !== ''" v-on:click="clear" class="gn-filtersubmit-clear">
            <span class="gn-text">Filter leegmaken</span>
            <span aria-hidden="true" class="gn-icon gn-icon-filter-clear"></span>
          </button>

          <button v-if="keyUpValue === ''" v-on:click="clear" class="gn-filtersubmit">
            <span class="gn-text">Filter</span>
            <span aria-hidden="true" class="gn-icon gn-icon-filter"></span>
          </button>

          <ul id="gn-search-results" aria-label="Zoekresultaten" role="listbox" v-bind="resultListProps" v-on="resultListListeners">
            <li aria-hidden="true">Zoekresultaten:</li>
            <li
                role="option"
                v-for="(result, index) in results"
                :key="resultProps[index].id"
                v-bind="resultProps[index]"
            >
              <a
                  @click="onClick"
                  :href="getUrl([
                         { name: 'q', value: result },
                       ])"
                  ref="autocompletelink" tabindex="-1">
                {{ result }}
              </a>
            </li>
          </ul>

          <span id="autocomplete-help-text" class="gn-sr-only">
              Als er zoekresultaten gevonden zijn kunt u de pijltjes toetsen omhoog en naar beneden gebruiken om een resultaat
              te selecteren. Toets enter om te navigeren naar het geselecteerde resultaat. Met de escape toets kunt u de
              invoer wissen.
            </span>
          <div id="autocomplete-search-results-announcer" aria-live="assertive" aria-atomic="true" class="gn-sr-only"></div>
        </div>
      </template>
    </autocomplete>
  </div>
</template>

<script>
import SearchMixin from "@/mixins/SearchMixin";
import Autocomplete from '@trevoreyre/autocomplete-vue'

export default {
  name: "SearchNijmegen",
  components: {
    Autocomplete
  },
  mixins: [
    SearchMixin,
  ],
  methods: {

    onkeyup(result) {

      // console.log('onkeyup')

      if(result.target.value === '') {
        // this.$store.commit('setFilteredFeatures', this.features)
        this.searchValue = ''
        this.keyUpValue = ''
      } else {
        this.keyUpValue = result.target.value
      }

      if(result.keyCode === 13) {
        this.searchValue = this.keyUpValue
        // this.$store.commit('setSearchValueLocation', '')
      }

      if(this.$refs.autocompletelink !== undefined) {
        this.$refs.autocompletelink.map((element) => {
          if(
              element.parentElement !== null && element.parentElement.getAttribute('aria-selected') === 'true')
          {
            element.classList.value = ['gn-autocomplete-result-link active']
          } else {
            element.classList.value = ['gn-autocomplete-result-link']
          }
        });
      }

      this.$store.commit('setActiveSubTab', '')
    },
  }
};
</script>

