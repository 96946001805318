<template>
  <div></div>
</template>

<script>
// import features from '../../assets/features.json'

export default {
  name: "Features",
  created() {
    this.loadFeatures();
  },
  methods: {
    async loadFeatures() {

      // this.$store.commit('setFeatures', features)
      // this.$store.commit('setFilteredFeatures', features)
    },
  }
}
</script>

<style scoped>

</style>
