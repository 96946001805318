import UrlMethodsMixin from "@/mixins/UrlMethodsMixin";

export default {
    mixins: [
        UrlMethodsMixin
    ],
    props: {
        feature: {}
    },
    computed: {
        mapSettings() {
            return this.$store.getters.getMap
        },
        detailSettings() {
            return this.$store.getters.getDetail
        }
    },
    methods: {
        generateAriaLabel(feature, attributes) {

            let ariaLabel = 'Lees meer over ';
            attributes.map( (attribute) => {
                ariaLabel += feature.properties[attribute.Attribute] + ' ';
            });

            return ariaLabel
        },
    }
}
