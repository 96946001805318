export default {
    computed: {
        getUpdateUrlParams() {
            return this.$store.getters.getUpdateUrlParams
        },
    },
    methods: {
        getUrl(elements) {
            return this.createUrl(elements)
        },
        updateUrl() {
            // console.log(this.getUpdateUrlParams)
            if(this.getUpdateUrlParams === 1) {
                const url = this.createUrl()

                window.history.pushState('', '', url);
            }
        },
        createUrl(elements = []) {
            const data = {}

            const urlQ = this.getUrlQ
            if(urlQ !== undefined && urlQ !== '') {
                data.q = urlQ
            }

            const urlPage = this.getUrlPage
            if(urlPage !== undefined && urlPage !== '') {
                data.page = urlPage
            }

            const urlFacetName = this.getUrlFacetName
            if(urlFacetName !== undefined && urlFacetName !== '') {
                data.facetName = urlFacetName
            }

            const urlFacetValue = this.getUrlFacetValue
            if(urlFacetValue !== undefined && urlFacetValue !== '') {
                data.facetValue = urlFacetValue
            }

            const activeFeature = this.getActiveFeature
            if(activeFeature !== undefined &&
                activeFeature !== null &&
                activeFeature.id !== undefined) {
                data.featureid = activeFeature.id
            }

            elements.map(element => {
                data[element.name] = element.value
            });

            return '?' + this.encodeQueryData(data)
        },
        encodeQueryData(data) {
            const ret = [];
            for (let d in data)
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
            return ret.join('&');
        }
    }
}
