export const feature = {
    state: {
        features: [],
        filteredFeatures: [],
        activeFeature: {},
        isFeatureFound: true,
        filterItems: [],
        filterFeatureSortProperty: '',
        activeFilters: []
    },
    getters: {
        getFeatures: state => {
            return state.features
        },
        getFilteredFeatures: state => {
            return state.filteredFeatures
        },
        getActiveFeature: state => {
            return state.activeFeature
        },
        isFeatureFound: state => {
            return state.isFeatureFound
        },
        getFilterItems: state => {
            return state.filterItems
        },
        getActiveFilters: state => {
            return state.activeFilters
        }
    },
    mutations: {
        setFeatures(state, features) {
            state.features = features
        },
        setFilteredFeatures(state, features) {
            state.filteredFeatures = features.sort( (a,b) => {

                if(a.properties.kv !== undefined && a.properties.kv.geometry !== undefined && b.properties.kv.geometry !== undefined) {
                    const bandA = a.properties.kv.geometry.value;
                    const bandB = b.properties.kv.geometry.value;

                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                    } else if (bandA < bandB) {
                        comparison = -1;
                    }
                    return comparison;
                } else if(state.filterFeatureSortProperty !== '') {
                    const bandA = a.properties[state.filterFeatureSortProperty];
                    const bandB = b.properties[state.filterFeatureSortProperty];

                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                    } else if (bandA < bandB) {
                        comparison = -1;
                    }
                    return comparison;
                }
                return 0
            });
        },
        setActiveFeature(state, feature) {

            // console.log('set active feature')

            state.activeFeature = feature
        },
        setFeatureFound(state, isFeatureFound) {
            state.isFeatureFound = isFeatureFound
        },
        setFilterItems(state, filterItems) {
            state.filterItems = filterItems
        },
        setFilterFeatureSortProperty(state, filterFeatureSortProperty) {
            state.filterFeatureSortProperty = filterFeatureSortProperty
        },
        setActiveFilters(state, activeFilters) {
            state.activeFilters = activeFilters
        },
        addActiveFilter(state, activeFilter) {
            state.activeFilters.push(activeFilter)
        },
        spliceActiveFilter(state, activeFilter) {
            state.activeFilters.splice(activeFilter, 1)
        }
    }
}
