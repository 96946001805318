<template>
  <div v-if="detailSettings !== null">

    <button type="button"
            v-on:click="setActiveTab($event, 'list')">
      <span aria-hidden="true" class="gn-icon gn-icon-left"></span>
      Terug naar zoekresultaten
    </button>

    <ol class="gn-breadcrumb" v-if="breadCrumbs.length >= 2">
      <li v-for="(breadCrumb, index) in breadCrumbs" :class="'gn-breadcrumb-item ' + isBreadCrumActive(index)"  v-bind:key="breadCrumb.ID">
        <template v-if="index === ( breadCrumbs.length - 1)">
          {{breadCrumb.FormName}}
        </template>
        <template v-else>
          <a href="#" title="Home" v-on:click="goToDetailPage($event, index)">
            {{breadCrumb.FormName}}
          </a>
        </template>
      </li>
    </ol>

    <template v-if="breadCrumbs.length === 1">
      <div v-for="form in forms" v-bind:key="form.ID">
        <template v-if="form.detailSettings !== undefined && form.presentationID === form.detailSettings.PresentationID">
          <h4 class="mt-2" v-html="form.detailSettings.FormName"></h4>
          <table-geo-novation
            v-bind:feature="activeFeature"
            v-bind:form-settings="form.detailSettings"
            v-bind:type="'detail'"
          ></table-geo-novation>
        </template>
      </div>
      <template v-if="presentationLinkedLoaded">
        <div v-for="presentationLinked in this.presentationsLinked" v-bind:key="presentationLinked.FormID">
          <h4 class="mt-2" v-html="presentationLinked.FormName"></h4>
          <table class="gn-table">
  <!--          <caption><h4>{{ presentationLinked.FormName }}</h4></caption>-->
            <thead>
              <tr>
                <th v-for="attributes in presentationLinked.attributes" v-bind:key="attributes.ID">
                  <template v-for="attribute in attributes">
                    <span v-bind:key="attribute.ID">{{attribute.DisplayName}}</span>
                  </template>
                </th>
                <th v-if="parseInt(presentationLinked.IsShowMoreInfoVisible) === 1">
                </th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="feature in presentationLinked.features" v-bind:key="feature.id">
              <td v-for="attributes in presentationLinked.attributes" v-bind:key="attributes.ID">
                <template v-for="attribute in attributes">
                  <div v-bind:key="attribute.ID">
                    <template v-if="parseInt(attribute.Type) === 0">
                      {{attribute.Prefix}}{{ feature.properties[attribute.Attribute] }}{{attribute.Suffix}}
                    </template>
                    <template v-else-if="parseInt(attribute.Type) === 1">
                      <!--                <img :src="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix" :title="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix">-->
                      <template v-if="isImage(attribute.Suffix, feature.properties[attribute.Attribute])">
                        <div class="row" v-bind:key="attribute.ID" >
                          <img class="col-md-4" style="display: block" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + detailSettings.DomainID + '/presentation/' + detailSettings.PresentationID + '/formattribute/' + attribute.FormAttributeID + '/item/0/filename/.jpg?fileName=' + feature.properties[attribute.Attribute] + attribute.Suffix" :title="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix">
                        </div>
                      </template>
                      <a v-bind:key="attribute.ID" v-if="!isImage(attribute.Suffix, feature.properties[attribute.Attribute])" class="open_link" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + detailSettings.DomainID + '/presentation/' + detailSettings.PresentationID + '/formattribute/' + attribute.FormAttributeID + '/item/0/filename/file?fileName=' + feature.properties[attribute.Attribute] + attribute.Suffix" target="_blank">
                        <template v-if="attribute.Action === 1">
                           {{feature.properties[attribute.Attribute] + attribute.Suffix}}
                        </template>
                        <template v-else>
                          Open link
                        </template>
                      </a>
                    </template>
                    <template v-else-if="parseInt(attribute.Type) === 3">
                      <a v-on:click="openLink(attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix)" target="_blank" :href="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix">{{ feature.properties[attribute.Attribute] }}{{attribute.Suffix}}</a>
                    </template>
                    <template v-else-if="parseInt(attribute.Type) === 402">
                      <div v-html="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix"></div>
                    </template>
                    <template v-else-if="parseInt(attribute.Type) === 404">
                      <div v-html="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix"></div>
                    </template>
                  </div>
                </template>
              </td>
              <td v-if="parseInt(presentationLinked.IsShowMoreInfoVisible) === 1">
                <a href="#"
                   v-on:click="loadLinkedPresentation($event, presentationLinked, feature)">
                  Lees meer
                </a>
              </td>
            </tr>
            <tr v-if="presentationLinked.features.length === 0">
              <td :colspan="presentationLinked.attributes.length">
                Geen {{ presentationLinked.FormName.toLowerCase() }} gevonden
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
    </template>

    <template v-if="breadCrumbs.length > 1">
      <template v-if="!presentationLinkedLoaded">
        <h4>
          Bezig met ophalen van de gegevens...
        </h4>
      </template>
      <template v-else>
        <template v-if="breadCrumbs[breadCrumbActiveIndex].featuresLinked.length === 0">
          <h4>
            Er zijn geen gegevens gevonden...
          </h4>
        </template>
        <template v-else>
          <template v-for="feature in breadCrumbs[breadCrumbActiveIndex].featuresLinked">

            <template v-for="(featureInfo, index) in feature.featureInfo">
              <h4 v-bind:key="featureInfo.PresentationID">{{featureInfo.FormName}}</h4>

              <template  v-if="featureInfo.records.length > 0">

                <template  v-if="index === 0">

                  <template v-if="detailSettings.FormType === 3">
                    <div class="" v-for="setting in featureInfo.records[0].attributes" :key="setting[0].ID">
                      <template v-for="settingItem in setting">
                        <div v-bind:key="settingItem.ID">
                          <template v-if="parseInt(settingItem.Type) === 0">
                            <h4>{{ settingItem.DisplayName }}</h4>
                            <p>{{settingItem.Prefix}}{{ settingItem.Value }}{{settingItem.Suffix}}</p>
                          </template>
                          <template v-else-if="parseInt(settingItem.Type) === 1">
                            <h4>{{ settingItem.DisplayName }}</h4>
                            <div class="row">
                              <div class="col-12">
                                <img :src="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix" :title="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">
                              </div>
                            </div>
                          </template>
                          <template v-if="parseInt(settingItem.Type) === 3">
                            <h4>
                              {{ settingItem.DisplayName }}
                            </h4>
                            <p>
                              <a target="_blank" :href="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">
                                {{settingItem.Prefix}}{{ activeFeature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}
                              </a>
                            </p>
                          </template>
                          <template v-if="parseInt(settingItem.Type) === 402">
                            <h4>{{ settingItem.DisplayName }}</h4>
                            <p>
                              {{activeFeature.properties[settingItem.Attribute]}}
                            </p>
                            <!--              <a target="_blank" :href="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">{{settingItem.Prefix}}{{ activeFeature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}</a>-->
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>

                    <table class="gn-table" v-bind:key="featureInfo.PresentationID">
                      <tbody>
                      <template v-for="setting in featureInfo.records[0].attributes">
                        <template v-for="settingItem in setting">
                          <tr v-bind:key="settingItem.ID">
                            <th class="">{{settingItem.DisplayName}}</th>
                            <td>
                              <template v-if="parseInt(settingItem.Type) === 0">
                                {{settingItem.Prefix}} {{settingItem.Value }} {{settingItem.Suffix}}
                              </template>
                              <template v-else-if="parseInt(settingItem.Type) === 1">
                                <template v-if="settingItem.FileStreamed === 1">
                                  <img class="col-md-4" style="display: block" v-if="isImage(settingItem.Suffix, activeFeature.properties[settingItem.Attribute])" v-bind:key="settingItem.ID" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + featureInfo.DomainID + '/presentation/' + featureInfo.PresentationID + '/formattribute/' + settingItem.FormAttributeID + '/item/0/filename/.jpg?fileName=' + settingItem.Value + settingItem.Suffix" :title="settingItem.Prefix + settingItem.Value + settingItem.Suffix">
                                  <a v-if="!isImage(settingItem.Suffix, activeFeature.properties[settingItem.Attribute])" v-bind:key="settingItem.ID" class="open_link" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + featureInfo.DomainID + '/presentation/' + featureInfo.PresentationID + '/formattribute/' + settingItem.FormAttributeID + '/item/0/filename/' + settingItem.Value + settingItem.Suffix + '?fileName=' + settingItem.Value + settingItem.Suffix" target="_blank">
                                    Open link
                                  </a>
                                </template>
                                <template v-else>
                                  <img :src="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix" :title="settingItem.Prefix + settingItem.Value + settingItem.Suffix">
                                </template>
                              </template>
                              <template v-else-if="parseInt(settingItem.Type) === 3">
                                <a target="_blank" :href="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">{{settingItem.Prefix}}{{ settingItem.Value }}{{settingItem.Suffix}}</a>
                              </template>
                              <template v-else-if="parseInt(settingItem.Type) === 21">
                                <template v-if="activeFeature.properties.kv.geometry !== undefined">
                                  {{ (activeFeature.properties.kv.geometry.value / 1000).toFixed(1) }} KM
                                </template>
                              </template>
                              <template v-else>
                                {{settingItem.Prefix}}{{ settingItem.Value }}{{settingItem.Suffix}}
                              </template>
                            </td>
                          </tr>
                        </template>
                      </template>
                      </tbody>
                    </table>
                  </template>
                </template>

                <template v-else>
                  <template v-if="featureInfo.records[0].attributes[0][0].Type === 1">
                    <div class="mdb-lightbox row" style="margin-left:-8px" v-bind:key="'body-' + featureInfo.FormID">
                      <figure
                          v-for="(feature, index) in featureInfo.records"
                          :key="index"
                          class="pic col-md-4"
                          @click="() => showImg(index)"
                      >
                        <a  :title="'Bekijk vergrote afbeelding van een '"
                            @click="clickImage" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + featureInfo.DomainID + '/presentation/' + featureInfo.PresentationID + '/formattribute/' + feature.attributes[0][0].FormAttributeID + '/item/' + index + '/filename/.jpg?fileName=' + feature.attributes[0][0].Value + '&_=' + new Date().getTime()"
                            data-size="1600x1067">
                          <img class="img-fluid" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + featureInfo.DomainID + '/presentation/' + featureInfo.PresentationID + '/formattribute/' + feature.attributes[0][0].FormAttributeID + '/item/' + index + '/filename/.jpg?fileName=' + feature.attributes[0][0].Value + '&_=' +  new Date().getTime()"/>
                        </a>
                      </figure>
                    </div>
                  </template>
                  <template v-else>
                    <table class="gn-table" v-bind:key="featureInfo.FormID">
                      <thead>
                      <tr>
                        <th v-for="attributes in featureInfo.records[0].attributes" v-bind:key="attributes.ID">
                          <!--                              <template v-for="attribute in attributes">-->
                          <span>{{attributes[0].DisplayName}}</span>
                          <!--                              </template>-->
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="record in featureInfo.records" v-bind:key="record.id">
                        <td v-for="attributes in record.attributes" v-bind:key="attributes.ID">
                          <template v-for="attribute in attributes">
                                <span v-bind:key="attribute.ID">
                                  {{attribute.Value}}
                                </span>
                          </template>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </template>
      </template>
    </template>


  </div>
</template>

<script>
import DetailMixin from "@/mixins/DetailMixin";
import TableGeoNovation from "@/layout/GeoNovation/TableGeoNovation";

export default {
  name: "DetailGeoNovation",
  components: {
    TableGeoNovation
  },
  watch: {
    activeFeature() {
      this.loadLinkedPresentations();
    }
  },
  mixins: [
    DetailMixin,
  ]
};
</script>


