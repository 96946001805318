<template>
  <div v-if="filterSettings !== null && filterSettings.records !== undefined">

    <div class="gn-facet" v-for="(setting, index) in filterSettings.records[0].attributes" :key="setting[0].ID" v-show="isGeometryLoaded(setting)">
      <template v-if="parseInt(setting[0].MutationTypeID) === 1023 || parseInt(setting[0].MutationTypeID) === 1022 || parseInt(setting[0].Type) === 21">
        <h2 class="gn-facet-title">
          <a href="#" class="gn-toggle" v-on:click="toggleFacetGroup($event, index)" role="button" :aria-expanded="openFacetGroups.includes(index) + ''" :aria-controls="'gn-facet_' + index">
            {{ setting[0].DisplayName }} <span :aria-label="getFacetTileAriaLabel(index)" class="gn-icon gn-icon-collapse"></span>
          </a>
        </h2>
        <ul :id="'gn-facet_' + index" class="gn-facet-list" :hidden="!openFacetGroups.includes(index)">

          <template v-for="filterItem in getFilterItems">
            <template v-if="filterItem.ID === setting[0].ID">
              <template v-for="feature in filterItem.features">
                    <template v-if="setting[0].MutationTypeID !== 1023">
                      <template v-if="parseInt(setting[0].Type) === 402 || parseInt(setting[0].Type) === 403 || parseInt(setting[0].Type) === 21">
                        <li class="gn-facet-list-item"  :key="feature.id" :class="activeFilterClass(feature.properties.kv[setting[0].Attribute].name, setting[0].ID, setting[0].Type)">
                          <a class="gn-facet-link" href="#"
                             v-on:click="filter($event, setting[0], feature.properties.kv[setting[0].Attribute], setting[0].DisplayName)">
                            {{ feature.properties.kv[setting[0].Attribute].name }}
                            <span>({{ feature.counter }})</span>
                          </a>
                        </li>
                      </template>
                      <template v-if="parseInt(setting[0].Type) === 0">
                        <li class="gn-facet-list-item"
                            :class="activeFilterClass(feature.properties[setting[0].Attribute])" :key="feature.id">
                          <a class="gn-facet-link" href="#" v-on:click="filter($event, setting[0], feature.properties[setting[0].Attribute], setting[0].DisplayName)">
                            {{ feature.properties[setting[0].Attribute] }}
                            <template v-if="feature.properties[setting[0].Attribute] === null">
                                Geen waarde
                            </template>
                            <span>({{ feature.counter }})</span>
                          </a>
                        </li>
                      </template>
                    </template>

<!--                    <template v-if="parseInt(setting[0].Type) === 21">-->
<!--                      <li class="gn-facet-list-item"-->
<!--                          :class="activeFilterClass(feature.properties.kv[setting[0].Attribute])"-->
<!--                          :key="feature.id">-->

<!--                        <a class="gn-facet-link" href="#" v-on:click="filter($event, setting[0], feature.properties.kv[setting[0].Attribute], setting[0].DisplayName)">-->
<!--                          <input type="checkbox"-->
<!--                                 class="gn-facet-facet-link-checkbox"-->
<!--                                 :id="'facet-item-checkbox-' + filterItem.ID + '-' + feature.id"-->
<!--                                 :checked="activeFilterCheckedClass(feature.properties.kv[setting[0].Attribute], filterItem.ID)"-->
<!--                          >-->
<!--                          {{ feature.properties.kv[setting[0].Attribute].name }}-->
<!--                          <template v-if="feature.properties[setting[0].Attribute] === null">-->
<!--                            Overig-->
<!--                          </template>-->
<!--                          <span>({{ feature.counter }})</span>-->
<!--                        </a>-->
<!--                      </li>-->
<!--                    </template>-->

                    <template v-if="setting[0].MutationTypeID === 1023">
                      <li class="gn-facet-list-item"
                          :class="activeFilterClass(feature.properties[setting[0].Attribute])"
                          :key="feature.id">

                        <a class="gn-facet-link" href="#" v-on:click="filter($event, setting[0], feature.properties[setting[0].Attribute], setting[0].DisplayName)">
                          <input type="checkbox"
                                 class="gn-facet-facet-link-checkbox"
                                 :id="'facet-item-checkbox-' + filterItem.ID + '-' + feature.id"
                                 :checked="activeFilterCheckedClass(feature.properties[setting[0].Attribute], filterItem.ID)"
                          >
                          {{ feature.properties[setting[0].Attribute] }}
                          <template v-if="feature.properties[setting[0].Attribute] === null">
                            Geen waarde
                          </template>
                          <span>({{ feature.counter }})</span>
                        </a>
                      </li>
                    </template>

              </template>
            </template>

          </template>

        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import FilterMixin from "@/mixins/FilterMixin";

export default {
  name: "FilterGeoNovation",
  mixins: [
    FilterMixin
  ]
}
</script>


