var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('autocomplete',{ref:"autocompleteSearch",attrs:{"search":_vm.search},on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var rootProps = ref.rootProps;
var inputProps = ref.inputProps;
var inputListeners = ref.inputListeners;
var resultListProps = ref.resultListProps;
var resultListListeners = ref.resultListListeners;
var results = ref.results;
var resultProps = ref.resultProps;
return [_c('div',_vm._b({staticClass:"autocomplete autocomplete__form",attrs:{"role":"search"}},'div',rootProps,false),[_c('input',_vm._g(_vm._b({ref:"searchInput",staticClass:"autocomplete__input form-control",attrs:{"type":"text","id":"gn-autocomplete-search","placeholder":"Zoek in de lijst","autocomplete":"off","name":"gn-search-q","aria-controls":"autocomplete-results","aria-autocomplete":"both","aria-label":"Zoekveld","aria-describedby":"gn-autocomplete-help-text"},on:{"keyup":_vm.onkeyup}},'input',inputProps,false),inputListeners)),(_vm.keyUpValue !== '')?_c('button',{staticClass:"autocomplete__search-button",on:{"click":_vm.clear}},[_c('span',{staticClass:"sr-only"},[_vm._v("Maak de tekstbox voor het verfijnen van uw resultaat leeg")]),_c('span',{staticClass:"mdi mdi mdi-close",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.keyUpValue === '')?_c('button',{staticClass:"autocomplete__search-button"},[_c('span',{staticClass:"sr-only"},[_vm._v("Maak de tekstbox voor het verfijnen van uw resultaat leeg")]),_c('span',{staticClass:"mdi mdi-magnify",attrs:{"aria-hidden":"true"}})]):_vm._e(),_c('ul',_vm._g(_vm._b({staticClass:"autocomplete__result-list",attrs:{"aria-label":"Zoekresultaten","role":"listbox"}},'ul',resultListProps,false),resultListListeners),[_c('li',{staticClass:"autocomplete__section-header",attrs:{"aria-hidden":"true"}},[_vm._v("Zoekresultaten:")]),_vm._l((results),function(result,index){return _c('li',_vm._b({key:resultProps[index].id,staticClass:"autocomplete__result-item",attrs:{"role":"option"}},'li',resultProps[index],false),[_c('a',{ref:"autocompletelink",refInFor:true,staticClass:"autocomplete__result-link",attrs:{"href":_vm.getUrl([
                   { name: 'q', value: result } ]),"tabindex":"-1"},on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(result)+" ")])])})],2),_c('span',{staticClass:"sr-only",attrs:{"id":"gn-autocomplete-help-text"}},[_vm._v(" Als er zoekresultaten gevonden zijn kunt u de pijltjes toetsen omhoog en naar beneden gebruiken om een resultaat te selecteren. Toets enter om te navigeren naar het geselecteerde resultaat. Met de escape toets kunt u de invoer wissen. ")]),_c('div',{staticClass:"sr-only",attrs:{"id":"gn-autocomplete-search-results-announcer","aria-live":"assertive","aria-atomic":"true"}})])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }