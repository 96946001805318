import FeatureLinkedService from "@/services/feature-linked.service";

export default {
    data() {
        return {
            featuresLinked: [],
            presentationLinkedLoaded: false,
            visible: false,
            breadCrumbs: [],
            breadCrumbActiveIndex: 0,
            forms: [],
            index: 2,
            imgs: []
        };
    },
    computed: {
        activeFeature() {
            return this.$store.getters.getActiveFeature
        },
        detailSettings() {
            return this.$store.getters.getDetail
        },
        presentationsLinked() {
            return this.$store.getters.getPresentationsLinked
        },
        settings() {
            return this.$store.getters.getSettings
        },
        kaartViewerUrl() {
          return this.$store.getters.getKaartViewerUrl
        },
        detailsSettings() {
            return this.$store.getters.getDetails
        },
        previousActiveTab() {
            return this.$store.getters.getPreviousActiveTab
        }
    },
    created() {
        this.loadLinkedPresentations();
        // const presentationsLinked = this.presentationsLinked;
        // const bookmarkID = this.settings.BookmarkID;
        // let counter = this.presentationsLinked.length;
        //
        // presentationsLinked.map( ( presentationLinked )  => {
        //
        //     this.getFeatureLinked(bookmarkID, presentationLinked.DomainID, presentationLinked.PresentationID, presentationLinked.FormID, '?column=' + presentationLinked.keyColumn + '&value=' + this.activeFeature.properties[presentationLinked.keyColumnLinkedAttribute] + '&columnType=' + presentationLinked.keyColumnLinkedType).then(featureResponse => {
        //         // this.featuresLinked.push(featureResponse.data)
        //         presentationLinked.features = featureResponse.data.features
        //         counter--;
        //
        //         if(counter === 0) {
        //             this.presentationLinkedLoaded = true;
        //         }
        //     });
        // });
    },
    methods: {
        getFeatureLinked(bookmarkID, domainID, presentationID, formID, queryParams) {
            return FeatureLinkedService.getFeatures(this.kaartViewerUrl, bookmarkID, domainID, presentationID, formID, queryParams);
        },
        setActiveTab(e, activeTab) {
            e.preventDefault();

            activeTab = this.previousActiveTab
            this.$store.commit('isMapMounted', true);
            this.activeTab = activeTab;
            this.$store.commit('setActiveTab', activeTab);
            this.$store.commit('setActiveSubTab', '');
            this.$store.commit('setActiveFeature', {});
        },

        loadLinkedPresentations() {

            this.presentationLinkedLoaded = false

            const presentationsLinked = this.presentationsLinked;
            const bookmarkID = this.settings.BookmarkID;
            const detailSettings = this.detailSettings
            let counter = this.presentationsLinked.length;

            this.breadCrumbs = []
            this.breadCrumbs.push(detailSettings)

            this.imgs = []

            this.forms = []

            this.detailsSettings.map(detailSettingItem => {
                let form = {}
                form.presentationID = detailSettingItem.PresentationID
                form.sorting = detailSettingItem.Sorting
                form.formID = detailSettingItem.FormID
                form.detailSettings = detailSettingItem
                this.forms.push(form)
            });

            presentationsLinked.map( ( presentationLinked )  => {

                let form = {}
                form.presentationID = presentationLinked.PresentationID
                form.sorting = presentationLinked.Sorting
                this.forms.push(form)

                console.log(presentationLinked)

                let keyColumnValue
                // Geometry
                if([3,4,5].includes(presentationLinked.keyColumnLinkedType)) {
                    keyColumnValue = this.activeFeature.geometry
                } else {
                    keyColumnValue = this.activeFeature.properties[presentationLinked.keyColumnLinkedAttribute]
                }

                //
                console.log(presentationLinked)

                this.getOrPostFeatureLinked(bookmarkID, presentationLinked, keyColumnValue).then(featureResponse => {
                    // this.featuresLinked.push(featureResponse.data)
                    presentationLinked.features = featureResponse.data.features
                    counter--;

                    const featureInfo = presentationLinked
                    if (featureInfo.attributes.length > 0 && parseInt(featureInfo.attributes[0][0].Type) === 1) {
                        presentationLinked.features.map((feature, index) => {
                            const imgUrl = this.kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + this.settings.BookmarkID + '/domain/' + featureInfo.DomainID + '/presentation/' + featureInfo.PresentationID + '/formattribute/' + featureInfo.attributes[0][0].FormAttributeID + '/item/' + index + '/filename/.jpg?fileName=' + feature.properties[featureInfo.attributes[0][0].Attribute] + '&dt=' + new Date().getTime()
                            this.imgs.push(imgUrl)
                        })
                    }

                    if(counter === 0) {
                        this.presentationLinkedLoaded = true;

                        this.$store.commit('setPresentationsLinked', presentationsLinked);
                    }
                });
            });

            this.forms.sort((a, b) => {
                return a.sorting - b.sorting;
            })

            console.log(this.forms)
        },
        getOrPostFeatureLinked(bookmarkID, presentationLinked, keyColumnValue) {

            if(presentationLinked.keyColumnLinkedType <= 2) {
                return this.getFeatureLinked(bookmarkID, presentationLinked.DomainID, presentationLinked.PresentationID, presentationLinked.FormID, '?value=' + keyColumnValue + '&columnType=' + presentationLinked.keyColumnLinkedType + '&column=' + presentationLinked.keyColumn)
            }

            const feature = {
                geometry: keyColumnValue,
                keyColumn: presentationLinked.keyColumn,
                keyColumnType: presentationLinked.keyColumnLinkedType
            }

            const json = {
                json: feature
            }


            return this.postFeatureLinked(bookmarkID, presentationLinked.DomainID, presentationLinked.PresentationID, presentationLinked.FormID, json);
        },
        postFeatureLinked(bookmarkID, domainID, presentationID, formID, data) {
            return FeatureLinkedService.postFeatures(this.kaartViewerUrl, bookmarkID, domainID, presentationID, formID, data);
        },
        getFeatureLinkedByFid(bookmarkID, domainID, presentationID, featureID) {
            return FeatureLinkedService.getFeatureByFid(this.kaartViewerUrl, bookmarkID, domainID, presentationID, featureID);
        },
        handleHide() {
            this.visible = false
        },
        clickImage(e) {
            e.preventDefault();
        },
        showImg(index) {

            //console.log(index)

            this.index = index
            this.visible = true
        },
        loadLinkedPresentation(e, presentationLinked, feature) {
            e.preventDefault();

            this.breadCrumbs.push(presentationLinked)
            this.breadCrumbActiveIndex = this.breadCrumbs.length - 1

            this.presentationLinkedLoaded = false
            //console.log(this.presentationLinkedLoaded)
            this.getFeatureLinkedByFid(this.settings.BookmarkID, presentationLinked.DomainID, presentationLinked.PresentationID, feature.featureid).then(featureResponse => {

                this.imgs = []
                if(featureResponse.data.mainTabs !== undefined) {
                    presentationLinked.featuresLinked = featureResponse.data.mainTabs[0].features;
                    //

                    if(presentationLinked.featuresLinked[0].featureInfo !== undefined) {
                        presentationLinked.featuresLinked[0].featureInfo.map((featureInfo) => {

                            if (featureInfo.records.length > 0 && featureInfo.records[0].attributes[0][0].Type === 1) {
                                featureInfo.records.map((record, index) => {
                                    const imgUrl = this.kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + this.settings.BookmarkID + '/domain/' + featureInfo.DomainID + '/presentation/' + featureInfo.PresentationID + '/formattribute/' + record.attributes[0][0].FormAttributeID + '/item/' + index + '/filename/file.jpg?fileName=' + record.attributes[0][0].Value + '&dt=' + new Date().getTime()
                                    this.imgs.push(imgUrl)
                                })
                            }

                        });

                        //console.log(this.imgs)
                    }

                } else {
                    presentationLinked.featuresLinked = []
                }

                this.presentationLinkedLoaded = true

                //console.log(this.breadCrumbActiveIndex)
                //console.log(this.breadCrumbs)
            });
        },
        // goToDetailPage(e, index) {
        goToDetailPage(e) {
            e.preventDefault();

            //console.log(this.breadCrumbs.length)
            //console.log(this.presentationsLinked)

            this.breadCrumbs = this.breadCrumbs.splice(0,1)

            //console.log(this.breadCrumbs.length)
        },
        isBreadCrumActive(index) {
            if(index === (this.breadCrumbs.length - 1)) {
                return 'active'
            }
            return ''
        },
        getImageUrl(settingItem, activeFeature) {

            let url = settingItem.Prefix;
            if(activeFeature.properties[settingItem.Attribute] !== null) {
                url += activeFeature.properties[settingItem.Attribute]
            }
            url += settingItem.Suffix;

            return url
        },
        isImage(extension, value) {

            // let isImage = false
            let isImage = false

            let extensionCheck = extension

            if(typeof value === 'string' && value.includes('.')) {
                extensionCheck = value.split('.')[1]
            } else if(typeof extension === 'string' && extension.includes('.')) {
                extensionCheck = extension.split('.')
                extensionCheck = extensionCheck[extensionCheck.length - 1]
            }

            if(extensionCheck === '' && extension === '') {
                isImage = true
            }

            switch (extensionCheck.replace('.', '')) {
                case 'jpg':
                case 'png':
                case 'jpeg':
                case 'gif':
                case 'ico':
                    isImage = true;
            }

            return isImage
        }
    },
}
