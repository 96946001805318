<template>

<!--  role="tab" aria-selected="false" aria-controls="gn-maps-tab" id="gn-map" tabindex="-1" data-index="1"-->

  <button :aria-selected="activeTab === element ? 'true' : 'false'"
          :class="getClassName(element)" data-toggle="tab"
          role="tab"
          ref="tab"
          :aria-controls="tabPanelID"
          :tabindex="tabIndex"
          :data-index="dataIndex"
          :id="id"
          v-on:click="setActiveTab(element)">
    {{name}}
  </button>
</template>

<script>
export default {
  name: "SwitchComponent",
  props: {
    name: String,
    id: String,
    className: String,
    element: String,
    dataIndex: String,
    tabIndex: String,
    tabPanelID: String,
    focusTab: String
  },
  methods: {
    setActiveTab(activeTab) {

      this.$store.commit('isMapMounted', true);

      if(Object.keys(this.activeFeature).length === 0) {
        this.$store.commit('setActiveTab', activeTab);
        this.$store.commit('setPreviousActiveTab', activeTab);
      } else {
        setTimeout(() => {
          this.$store.commit('setActiveTab', activeTab);
          this.$store.commit('setPreviousActiveTab', activeTab);
        }, 600)
      }

      this.$emit('setFocusTab', activeTab)
    },
    getClassName(element) {
        let className = this.className
        if(element === this.activeTab) {
          className = 'active';
        } else {
          className = ''
        }
        return className;
    },
    setFocus() {

    }
  },
  watch: {
    focusTab(focusTab) {

      //console.log(focusTab)
      //console.log(this.element)

      if(focusTab === this.element) {
        this.$refs.tab.focus();
      }
    }
  },
  computed: {
    activeTab() {
      return this.$store.getters.getActiveTab
    },
    activeFeature() {
      return this.$store.getters.getActiveFeature
    },
  }
}
</script>

<style scoped>

</style>
