
export default {
    methods: {
        goToPreviousPage(e) {
            e.preventDefault();
            const n = this.activePage;
            this.$store.commit('setActivePage', n - 1);
            this.$store.commit('setUrlPage', n)
        },
        goToNextPage(e) {
            e.preventDefault();
            let n = this.activePage;
            this.$store.commit('setActivePage', n + 1);
            this.$store.commit('setUrlPage', n + 2)
        },
        goToPage(n, e) {
            e.preventDefault();

            this.$store.commit('setActivePage', n - 1);
            this.$store.commit('setUrlPage', n)
        },
        updateUrl() {

        }
    }
}

