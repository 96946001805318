<template>
  <div id="geocomponent"
       :data-kaart-viewer-url="kaartViewerUrl"
       :data-bookmark-name="bookmarkName"
       :data-bookmark-group-name="bookmarkGroupName"
       :data-cluster-zoom="clusterZoom"
       :data-show-combi="layoutCombi"
       :data-split-left="splitLeft"
       :data-map-first="mapFirst"
       :data-split-right="splitRight"
       :data-hide-search="hideSearch"
       :data-hide-location-search="hideLocationSearch"
       :data-zoom-filter-result="zoomToFilterResult"
       :data-dependent-filter="dependentFilter"
       :data-update-url-params="updateUrlParams"
  >
    <layout/>
  </div>
</template>

<script>

import Layout from "./layout/Layout";
import FeatureService from './services/feature.service'
import SettingService from './services/setting.service'
import {LatLng, latLng} from "leaflet";

export default {
  name: 'Geocomponent',
  props: {
    kaartViewerUrl: String,
    bookmarkName: String,
    bookmarkGroupName: String,
    clusterZoom: String,
    layoutCombi: String,
    splitLeft: String,
    splitRight: String,
    hideSearch: String,
    hideLocationSearch: String,
    zoomToFilterResult: String,
    mapFirst: String,
    dependentFilter: String,
    updateUrlParams: String
  },
  created() {

    const parsedQueryString = this.parseQueryString();

    if (parsedQueryString.website !== undefined) {
      this.bookmarkName = parsedQueryString.website
    }
    if (parsedQueryString.groep !== undefined) {
      this.bookmarkGroupName = parsedQueryString.groep
    }

    if (parsedQueryString.showcombi !== undefined) {
      this.layoutCombi = parsedQueryString.showcombi
    }
    if (parsedQueryString.splitleft !== undefined) {
      this.splitLeft = parsedQueryString.splitleft
    }
    if (parsedQueryString.splitright !== undefined) {
      this.splitRight = parsedQueryString.splitright
    }
    if (parsedQueryString.clusterzoom !== undefined) {
      this.clusterZoom = parsedQueryString.clusterzoom
    }
    if (parsedQueryString.hidesearch !== undefined) {
      this.hideSearch = parsedQueryString.hidesearch
    }
    if (parsedQueryString.hidelocationsearch !== undefined) {
      this.hideLocationSearch = parsedQueryString.hidelocationsearch
    }
    if (parsedQueryString.zoomtofilterresult !== undefined) {
      this.zoomToFilterResult = parsedQueryString.zoomtofilterresult
    }
    if (parsedQueryString.mapfirst !== undefined) {
      this.mapFirst = parsedQueryString.mapfirst
    }
    if (parsedQueryString.dependentfilter !== undefined) {
      this.dependentFilter = parsedQueryString.dependentfilter
    }
    if (parsedQueryString.updateurlparams !== undefined) {
      this.updateUrlParams = parsedQueryString.updateurlparams
    }

    if(this.splitLeft !== undefined && this.splitLeft !== null && this.splitLeft !== '') {
      this.$store.commit('setSplitLeft', this.splitLeft)
    }
    if(this.splitRight !== undefined && this.splitRight !== null && this.splitRight !== '') {
      this.$store.commit('setSplitRight', this.splitRight)
    }
    if(this.layoutCombi !== undefined && this.layoutCombi !== null && this.layoutCombi !== '') {
      this.$store.commit('setLayoutCombi', this.layoutCombi)
    }
    // console.log('kaartviewrurl 1 = ' + this.kaartViewerUrl)
    if(this.kaartViewerUrl !== undefined && this.kaartViewerUrl !== null && this.kaartViewerUrl !== '') {
      this.$store.commit('setKaartViewerUrl', this.kaartViewerUrl)
    }
    if(this.hideSearch  !== undefined && this.hideSearch  !== null && this.hideSearch  !== '') {
      this.$store.commit('setHideSearch', parseInt(this.hideSearch))
    }
    if(this.hideLocationSearch !== undefined && this.hideLocationSearch  !== null && this.hideLocationSearch  !== '') {
      this.$store.commit('setHideLocationSearch', parseInt(this.hideLocationSearch))
    }
    if(this.mapFirst !== undefined && this.mapFirst  !== null && this.mapFirst  !== '') {
      this.$store.commit('setMapFirst', parseInt(this.mapFirst))
    }
    if(this.updateUrlParams !== undefined && this.updateUrlParams  !== null && this.updateUrlParams  !== '') {
      this.$store.commit('setUpdateUrlParams', parseInt(this.updateUrlParams))
    }

    // console.log(this.dependentFilter)
    // if(this.dependentFilter !== undefined && this.dependentFilter  !== null && this.dependentFilter  !== '') {
    //   this.$store.commit('setDependentFilter', parseInt(this.dependentFilter))
    // }

    // console.log(parseInt(this.dependentFilter))
    if(this.zoomToFilterResult !== undefined && this.zoomToFilterResult  !== null && this.zoomToFilterResult  !== '') {
      this.$store.commit('setZoomToFilterResult', parseInt(this.zoomToFilterResult))
    }

    var url = window.location.pathname;
    var filename = url.substring(url.lastIndexOf('/') + 1);
    if (this.kaartViewerUrl === undefined || this.kaartViewerUrl === '') {
      this.kaartViewerUrl = window.location.origin + window.location.pathname.replace('/geocomponent/' + filename, '')
      this.$store.commit('setKaartViewerUrl', this.kaartViewerUrl)
    }
    // console.log('kaartviewrurl 2 = ' + window.location.origin + window.location.pathname.replace('/geocomponent/' + filename, ''))
    // console.log('kaartviewrurl 3 = ' + this.kaartViewerUrl)

    SettingService.getSettings(this.kaartViewerUrl, this.bookmarkName, this.bookmarkGroupName).then((response) => {
      const settings = response.data

      if(settings.gemeente.extraCssFile.includes('nijmegen')) {
        this.$store.commit('setTemplateName', 'nijmegen')
      } else {
        this.$store.commit('setTemplateName', 'geonovation')
      }

      this.$store.commit('setSettings', settings)
      this.$store.commit('setMapCenter', latLng(settings.gemeente.rd.lat, settings.gemeente.rd.lng));
      const bboxrd = settings.gemeente.bboxrd;
      this.$store.commit('setMapBounds', [new LatLng(bboxrd[0], bboxrd[1]), new LatLng(bboxrd[2], bboxrd[3])]);

      if (this.clusterZoom !== undefined && this.clusterZoom !== null && this.clusterZoom !== '') {
        this.$store.commit('setClusterZoom', this.clusterZoom);
      }
      this.$store.commit('setDefaultMapCenter', latLng(settings.gemeente.rd.lat, settings.gemeente.rd.lng));

      let filterForm = null;
      let presentationsLinked = [];
      let forms = [];

      this.$store.commit('setWmsLayers', settings.wms)

      // this.$getLocation({enableHighAccuracy: true}).then(position => {
      //   position.type = 'gps';
      //   this.$store.commit('setGeoLocation', position);
      // }, () => {
      //   const position = {};
      //   position.lat = settings.gemeente.rd.lat;
      //   position.lng = settings.gemeente.rd.lng;
      //   position.type = 'boundingbox';
      //
      //   this.$store.commit('setGeoLocation', position);
      // }).finally(() => {

        if(settings.sub.length > 0 && settings.sub[0] !== undefined && settings.sub[0].featureInfo !== null) {

          const bookmarkID = settings.sub[0].BookmarkID;
          const domainID = settings.sub[0].DomainID;
          const presentationID = settings.sub[0].PresentationID;
          const typeName = settings.sub[0].service.typeName;

          settings.sub[0].featureInfo.map((featureInfo) => {

            if(parseInt(presentationID) !== parseInt(featureInfo.PresentationID)) {

                featureInfo.features = []
               presentationsLinked.push(featureInfo);
            }

            if (parseInt(featureInfo.ApplicationID) === 0) {
              forms.push(featureInfo);
            } else {
              filterForm = featureInfo;
            }
          });

          this.$store.commit('setList', forms[0])
          this.$store.commit('setFilterFeatureSortProperty', forms[0].records[0].attributes[0][0].Attribute)

          this.$store.commit('setMap', forms[1])

          let detailForm = null
          let detailForms = []
          forms.map( (form, key) => {
              if(key > 1 && form.PresentationID === presentationID) {
                  detailForm = form
                detailForms.push(form)
              }
          })

          this.$store.commit('setDetail', detailForm)
          this.$store.commit('setDetails', detailForms)

          this.$store.commit('setFilter', filterForm)
          this.$store.commit('setPresentationsLinked', presentationsLinked)

          // const cqlFilter = settings.sub[0].service.CQL_FILTER;

          let cqlFilter = ''
          let count = 1
          settings.sub.map( (sub) => {

              if(sub.cqlkolom !== null && sub.cqlkolom !== '') {

                if(count === 1) {
                    cqlFilter = sub.cqlkolom + "=(";
                }

                cqlFilter += '\'' + sub.cqlwaarde + '\''

                count++;

                if (count <= settings.sub.length) {
                  cqlFilter += ','
                } else {
                  cqlFilter += ')';
                }
              }
          });


          const queryParams = '?ts=1593785369678&service=WFS&version=1.1.0&srsName=EPSG%3A4326&request=GetFeature&typeName=' + typeName + '&outputFormat=json&CQL_FILTER=' + cqlFilter;

          FeatureService.getFeatures(this.kaartViewerUrl, bookmarkID, domainID, presentationID, queryParams).then(featureResponse => {
            const features = featureResponse.data.features

            let dateProperty = '';
            features.map((feature) => {
              feature.properties.kv = {}

              if (this.geoLocation !== null && feature.geometry !== null) {
                const gpsX = this.geoLocation.lng;
                const gpsY = this.geoLocation.lat;
                const geometryName = 'geometry';

                let objX;
                let objY;

                const newCoordinates = [];

                console.log('test')

                switch (feature.geometry.type) {
                  case 'Point':
                    objX = feature.geometry.coordinates[0];
                    objY = feature.geometry.coordinates[1];
                    break;
                  case 'Polygon':
                    feature.geometry.coordinates[0].map((coordinate) => {
                      newCoordinates.push([coordinate[1], coordinate[0]]);
                    });
                    objX = window.L.latLngBounds(newCoordinates).getCenter().lng;
                    objY = window.L.latLngBounds(newCoordinates).getCenter().lat;
                    break;
                }

                const distance = this.calculateDistance([gpsX, gpsY], [objX, objY]);

                feature.properties.kv = {};
                feature.properties.kv[geometryName] = {}
                feature.properties.kv[geometryName].value = distance

                if (distance <= 50000) {
                  feature.properties.kv[geometryName].name = 'Binnen 50 km';
                }
                if (distance <= 10000) {
                  feature.properties.kv[geometryName].name = 'Binnen 10 km';
                }
                if (distance <= 5000) {
                  feature.properties.kv[geometryName].name = 'Binnen 5 km';
                }
                if (distance <= 2000) {
                  feature.properties.kv[geometryName].name = 'Binnen 2 km';
                }
                if (distance <= 1000) {
                  feature.properties.kv[geometryName].name = 'Binnen 1 km';
                }
                if (distance <= 500) {
                  feature.properties.kv[geometryName].name = 'Binnen 500 m';
                }
                if (distance <= 200) {
                  feature.properties.kv[geometryName].name = 'Binnen 200 m';
                }
              }

              if (filterForm !== null) {
                filterForm.records[0].attributes.map((attributes) => {
                  attributes.map((attribute) => {
                    switch (parseInt(attribute.Type)) {
                      case 402:
                        if(feature.properties[attribute.Attribute] !== null) {
                          dateProperty = feature.properties[attribute.Attribute].split('-');
                          dateProperty = Math.round((new Date(dateProperty[2] + '-' + dateProperty[1] + '-' + dateProperty[0])).getTime() / 100000000);
                          feature.properties.kv[attribute.Attribute] = {}
                          feature.properties.kv[attribute.Attribute].value = dateProperty
                        }
                        break;
                    }
                  });
                });
              }
            });

            this.$store.commit('setFeatures', features)
            this.$store.commit('setFilteredFeatures', features)

          }, () => {
            this.$store.commit('setFeatureFound', false)
            // console.log(error.response.status)
          });
        }
      });
    // })
  },
  components: {
    Layout
  },
  computed: {
    geoLocation() {
      return this.$store.getters.getGeoLocation
    },
  },
  methods: {
    parseQueryString: () => {
      const str = window.location.search;
      const objURL = {};
      str.replace(
          new RegExp("([^?=&]+)(=([^&]*))?", "g"),
          function ($0, $1, $2, $3) {
            objURL[$1] = $3;
          }
      );
      return objURL;
    },
    toRadian(degree) {
      return degree * Math.PI / 180;
    },
    calculateDistance(origin, destination) {
      // return distance in meters
      var lon1 = this.toRadian(origin[1]),
          lat1 = this.toRadian(origin[0]),
          lon2 = this.toRadian(destination[1]),
          lat2 = this.toRadian(destination[0]);

      var deltaLat = lat2 - lat1;
      var deltaLon = lon2 - lon1;

      var a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
      var c = 2 * Math.asin(Math.sqrt(a));
      var EARTH_RADIUS = 6371;
      // return c * EARTH_RADIUS * 1000;
      return c * EARTH_RADIUS * 1000;
    },
  }
}
</script>

<style>
.search-results_item-geom {
  float: right;
  margin-right: 3px;
  font-weight: bold;
  font-size: 1rem;
  padding: 2px;
  border: 1px dashed rgb(21, 124, 104);
  color: rgb(21, 124, 104);
}
</style>
<!--<style>-->
<!--    #content .container {-->
<!--        margin-top: 82px;-->
<!--        max-width: 920px;-->
<!--    }-->

<!--    .col-md-3 {-->
<!--        -ms-flex: 0 0 25%;-->
<!--        flex: 0 0 25%;-->
<!--        max-width: 25%;-->
<!--    }-->

<!--    .col-md-9 {-->
<!--        -ms-flex: 0 0 75%;-->
<!--        flex: 0 0 75%;-->
<!--        max-width: 75%;-->
<!--    }-->

<!--    .facets__group-title {-->
<!--        color: #a80a2d;-->
<!--        font-size: 1.75rem;-->
<!--    }-->

<!--    .col-lg-4 {-->
<!--        -ms-flex: 0 0 33.333333%;-->
<!--        flex: 0 0 33.333333%;-->
<!--        max-width: 33.333333%;-->
<!--    }-->

<!--    .col-md-12 {-->
<!--        -ms-flex: 0 0 100%;-->
<!--        flex: 0 0 100%;-->
<!--        max-width: 100%;-->
<!--    }-->

<!--    .col-sm-12 {-->
<!--        -ms-flex: 0 0 100%;-->
<!--        flex: 0 0 100%;-->
<!--        max-width: 100%;-->
<!--    }-->

<!--    .col-12 {-->
<!--        -ms-flex: 0 0 100%;-->
<!--        flex: 0 0 100%;-->
<!--        max-width: 100%;-->
<!--    }-->

<!--    .row {-->
<!--        display: -ms-flexbox;-->
<!--        display: flex;-->
<!--        -ms-flex-wrap: wrap;-->
<!--        flex-wrap: wrap;-->
<!--        margin-right: -15px;-->
<!--        margin-left: -15px;-->
<!--    }-->

<!--    .justify-content-md-center {-->
<!--        -ms-flex-pack: center !important;-->
<!--        justify-content: center !important;-->
<!--    }-->

<!--    .form-control {-->
<!--        display: block;-->
<!--        width: 100%;-->
<!--        height: calc(1.5em + .75rem + 2px);-->
<!--        padding: .375rem .75rem;-->
<!--        padding-right: 0.75rem;-->
<!--        font-size: 1rem;-->
<!--        font-weight: 400;-->
<!--        line-height: 1.5;-->
<!--        color: #495057;-->
<!--        background-color: #fff;-->
<!--        background-clip: padding-box;-->
<!--        border: 1px solid #ced4da;-->
<!--        border-radius: .25rem;-->
<!--        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;-->
<!--    }-->

<!--    .autocomplete__clear-button, .autocomplete__search-button {-->
<!--        background-color: transparent;-->
<!--        border: 0;-->
<!--        box-sizing: content-box;-->
<!--        display: block;-->
<!--        float: right;-->
<!--        height: 30px;-->
<!--        padding-top: 3px;-->
<!--        position: relative;-->
<!--        top: -35px;-->
<!--        width: 30px;-->
<!--    }-->

<!--    .sr-only {-->
<!--        position: absolute;-->
<!--        width: 1px;-->
<!--        height: 1px;-->
<!--        padding: 0;-->
<!--        overflow: hidden;-->
<!--        clip: rect(0, 0, 0, 0);-->
<!--        white-space: nowrap;-->
<!--        border: 0;-->
<!--    }-->

<!--    .mdi-set, .mdi::before {-->
<!--        display: inline-block;-->
<!--        font: normal normal normal 24px/1 Material Design Icons;-->
<!--        font-size: 24px;-->
<!--        line-height: 1;-->
<!--        font-size: inherit;-->
<!--        text-rendering: auto;-->
<!--        line-height: inherit;-->
<!--        -webkit-font-smoothing: antialiased;-->
<!--        -moz-osx-font-smoothing: grayscale;-->
<!--    }-->

<!--    .text-left {-->
<!--        text-align: left !important;-->
<!--    }-->

<!--    .col-md-auto {-->
<!--        -ms-flex: 0 0 auto;-->
<!--        flex: 0 0 auto;-->
<!--        width: auto;-->
<!--        max-width: 100%;-->
<!--    }-->

<!--    .pills {-->
<!--        display: -webkit-box;-->
<!--        display: flex;-->
<!--        flex-wrap: wrap;-->
<!--        list-style: none;-->
<!--        margin: 0;-->
<!--    }-->

<!--    .pills__item {-->
<!--        padding: .6rem;-->
<!--    }-->

<!--    .pills__button, .pills__link {-->
<!--        background: none;-->
<!--        border-radius: 2px;-->
<!--        color: #157c68;-->
<!--        display: inline-block;-->
<!--        padding: .5rem 1rem;-->
<!--    }-->

<!--    .pills__button {-->
<!--        border: 0;-->
<!--    }-->

<!--    .pills__button[aria-current], .pills__button[aria-selected="true"], .pills__link[aria-current], .pills__link[aria-selected="true"] {-->
<!--        background: #157c68;-->
<!--        color: #fff;-->
<!--    }-->

<!--    a {-->
<!--        color: #157c68;-->
<!--    }-->

<!--    a:hover {-->
<!--        text-decoration: none;-->
<!--        -webkit-transition: all .2s ease-in-out;-->
<!--        transition: all .2s ease-in-out;-->
<!--    }-->

<!--    .facets__item::first-letter {-->
<!--        text-transform: capitalize;-->
<!--    }-->

<!--    .facets__item {-->
<!--        line-height: 1.5rem;-->
<!--        position: relative;-->
<!--    }-->

<!--    dl, ol, ul {-->
<!--        list-style: none;-->
<!--    }-->

<!--    dl, ol, ul {-->
<!--        list-style: none;-->
<!--        padding: 0;-->
<!--    }-->

<!--    dl, ol, ul {-->
<!--        margin-top: 0;-->
<!--        margin-bottom: 1rem;-->
<!--    }-->

<!--    .search-results__title h1 {-->
<!--        margin-bottom: 1.5rem;-->
<!--        font-size: 1.25rem;-->
<!--        text-align: left;-->
<!--    }-->

<!--    .search-results__item-title em {-->
<!--        color: #a80a2d;-->
<!--        font-size: 1.5rem;-->
<!--        font-feature-settings: "liga";-->
<!--        font-family: Oranda, sans-serif;-->
<!--        font-weight: 400;-->
<!--        -moz-osx-font-smoothing: grayscale;-->
<!--        text-rendering: optimizeLegibility;-->
<!--    }-->

<!--    .search-results__item {-->
<!--        border-bottom: 1px solid #dadada;-->
<!--        margin-bottom: 1.25rem;-->
<!--        padding-bottom: 1.25rem;-->
<!--    }-->
<!--</style>-->
