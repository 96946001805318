import UrlMethodsMixin from "@/mixins/UrlMethodsMixin";

export default {
    mixins: [
        UrlMethodsMixin
    ],
    data() {
        return {
            searchValue: '',
            keyUpValue: '',
        }
    },
    watch: {
        getSearchValue: function(getSearchValue) {
            if(getSearchValue === '') {
                this.searchValue = ''
                this.keyUpValue = ''
                this.$refs.autocompleteSearch.setValue('')
            }
        },
        searchValue: function (searchValue) {
            this.$store.commit('setSearchValue', searchValue)
            this.$store.commit('setUrlQ', searchValue)
        }
    },
    methods: {
        checkFeatureProperties(feature, searchValue) {
            for (const key in feature.properties) {
                const obj = feature.properties[key];
                if (obj !== null) {
                    if (obj.toString().toLowerCase().includes(searchValue.toLowerCase())) {
                        return true;
                    }
                }
            }
            return false;
        },
        handleFormSubmit(event) {

            console.log('clear')

            event.preventDefault()
        },
        search(input) {
            if (input.length < 1) { return [] }

            let result = []

            // if(key === 0) {
            const filterSettingRecordsAttributes = []
            this.filterSettings.records[0].attributes.map(function(result) {
                filterSettingRecordsAttributes.push(result[0].Attribute)
            });

            this.features.map((feature) => {
                for (const [key, value] of Object.entries(feature.properties)) {
                    if(
                        value !== null &&
                        this.isObject(value) === false &&
                        String(value).toLowerCase().includes(input.toLowerCase()) &&
                        filterSettingRecordsAttributes.includes(key)
                    ) {
                        result.push(value)
                    }
                }
            });

            return [...new Set(result)].slice(0, 5)
        },
        onSubmit(result) {
            const clusterZoom = this.clusterZoom
            if (result !== undefined && clusterZoom > 0) {

                // this.$store.commit('setSearchValueLocation', '')

                const filteredFeatures = []
                this.features.map((feature) => {
                    if (this.checkFeatureProperties(feature, result)) {
                        filteredFeatures.push(feature);
                    }
                });

                if(filteredFeatures.length === 1) {
                    this.$store.commit('setClusterZoom', 0)
                    // this.$store.commit('setFilteredFeatures', this.features)
                    this.searchValue = result
                    setTimeout(() => {
                        this.$store.commit('setClusterZoom', clusterZoom)
                    })
                } else {
                    // this.$store.commit('setFilteredFeatures', this.features)
                    this.searchValue = result
                }

            } else {
                // this.$store.commit('setFilteredFeatures', this.features)
                this.searchValue = result
            }
        },
        onClick(e) {
            e.preventDefault()
        },
        clear(e) {
            e.preventDefault()
            // this.$store.commit('setFilteredFeatures', this.features)
            this.searchValue = ''
            this.keyUpValue = ''
            this.$refs.autocompleteSearch.setValue('')
        },
        isObject(object) {
            return typeof object === 'object' && object !== null
        }
    },
    computed: {
        features() {
            return this.$store.getters.getFeatures
        },
        filteredFeatures() {
            return this.$store.getters.getFilteredFeatures
        },
        filterSettings() {
            return this.$store.getters.getFilter
        },
        clusterZoom() {
            return this.$store.getters.getClusterZoom
        },
        getSearchValue() {
            return this.$store.getters.getSearchValue
        },
    }
}
