<template>
  <div id="map-leaflet-map" v-bind:style="offsetDivMapHeight" @click="handleClick">

    <div v-html="custom_style"></div>
    <l-map
        ref="map"
        v-if="getMapBounds !== null && getMapBounds !== undefined"
        :options="mapOptions"
        :crs="crs"
        :max-zoom="16"
        :bounds="getMapBounds"
        v-bind:style="'height: ' + offsetMapHeight"
    >
      <l-control position="topleft">
        <div class="row">
          <div class="col-12">
            <button v-on:click="zoomIn" class="btn btn-sm btn-secondary mdi mdi-magnify-plus waves-effect waves-light" title="Klik hier om in te zoomen" style="width: auto; font-size: 20px;">
              <span class="sr-only">Klik hier om in te zoomen</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button v-on:click="zoomOut" class="btn btn-sm btn-secondary mdi mdi-magnify-minus waves-effect waves-light" title="Klik hier om uit te zoomen" style="width: auto; font-size: 20px;">
              <span class="sr-only">Klik hier uit te zoomen</span>
            </button>
          </div>
        </div>
      </l-control>
      <l-control-scale position="bottomright" :imperial="false" :metric="true"></l-control-scale>
      <l-control :position="'topright'" class="custom-control-legend">
        <template v-for="layer in wmsLayers">
          <div v-bind:key="layer.id" v-if="layer.legenda !== ''">
            <div style="margin-left:5px;"><b>{{ layer.naam }}</b></div>
            <div style="margin-left:5px;">
              <img :src="layer.legenda + '&transparent=true'" alt="legenda" class="img-legend"/>
            </div>
          </div>
        </template>
<!--        <template v-for="layer in settings.sub">-->
          <div v-if="settings.sub[0] !== undefined && settings.sub[0].legenda !== ''">
            <div style="margin-left:5px;"><b>{{ settings.sub[0].PresentationName }}</b></div>
            <div style="margin-left:5px;">
              <img :src="settings.sub[0].legenda + '&transparent=true'" alt="legenda" class="img-legend"/>
            </div>
          </div>

        <div>
          <template v-if="showWfsLegend() === true">
              <div style="margin-left:5px;"><b>{{ settings.sub[0].PresentationName }}</b></div>
              <ul style="list-style: none;padding-left:2px;margin-top:4px;">
                <template v-for="layer in settings.sub" >
                  <li v-if="layer.wfs_style.LegendImage !== ''" v-bind:key="layer.id" >
                  <img :style="'border-radius:' + wfsLegendBorderRadius() + ';background-color:' + layer.wfs_style.LegendBackgroundColor"
                        style="
                        float:left;
                        margin-left:4px;
                        margin-top:3px;
                        height:15px;"
                        :src="layer.wfs_style.LegendImage" >

    <!--                <object class="icon-bike" style="-->
    <!--                    float:left;-->

    <!--                    margin-left:4px;-->
    <!--                    margin-top:3px;-->
    <!--                    height:13px;" :data="layer.wfs_style.externalGraphic" type="image/svg+xml">-->
    <!--                  <img :src="layer.wfs_style.externalGraphic" />-->
    <!--                </object>-->
    <!--                <span class="fa fa-slash" style="color: green"></span>-->
                    <div style="text-align: right; display:inline-block;padding-top:2px;padding-left:7px;">{{layer.display}}</div>

                  </li>
                </template>
              </ul>
          </template>
        </div>
      </l-control>
      <v-icondefault :image-path="'/statics/leafletImages/'">
      </v-icondefault>
      <!--      <map-leaflet-title-layer/>-->
      <template v-for="layer in settings.achtergrondkaarten">
        <template v-if="layer.type === 'TMS'">
          <l-tile-layer
              :options="tileLayerOptions(layer)"
              v-bind:key="layer.id"
              :visible="visibleMap === layer.naam" :tms="true"
              :url="layer.url + layer.versie + '/' + layer.layer + '/{z}/{x}/{y}.png'"
          />
        </template>
        <template v-if="layer.type === 'WMTS'">
          <l-tile-layer
              :options="tileLayerOptions(layer, false)"
              v-bind:key="layer.id"
              :visible="visibleMap === layer.naam"
              :tms="false"
              :url="layer.url + layer.layer + '/EPSG:28992/{z}/{x}/{y}.png'"
          />
        </template>
        <template v-if="layer.type === 'WMS'">
          <l-wms-tile-layer
              :options="tileLayerOptions(layer)"
              v-bind:key="layer.id"
              :base-url="layer.url"
              :layers="layer.layer"
              :name="layer.naam"
              :visible="visibleMap === layer.naam"
              layer-type="base">
          </l-wms-tile-layer>
        </template>

      </template>

      <template v-for="layer in wmsLayers">
        <template v-if="layer.type === 'TMS'">
          <l-tile-layer
              :options="tileLayerOptions(null, true)"
              v-bind:key="layer.id"
              :tms="true"
              :zIndex="layer.displaysorting"
              :visible="showWmsLayers"
              :url="layer.url + layer.versie + '/' + layer.layer + '/{z}/{x}/{y}.png'"/>
        </template>
        <template v-if="layer.type === 'WMS'">
          <l-wms-tile-layer
              :options="tileLayerOptions(layer, true)"
              v-bind:key="layer.id"
              :base-url="layer.url"
              :layers="layer.layer"
              :transparent="true"
              :visible="showWmsLayers"
              :opacity="parseInt(layer.opacity) / 10"
              :format="'image/png'"
              :zIndex="layer.displaysorting"
              :name="layer.naam"
              layer-type="base"
            :styles="layer.style">
          </l-wms-tile-layer>
        </template>
        <template v-if="layer.type === 'WMTS'">
          <l-tile-layer
              :options="tileLayerOptions(null, false)"
              v-bind:key="layer.id"
              :tms="false"
              :zIndex="layer.displaysorting"
              :visible="showWmsLayers"
              :url="layer.url + layer.layer + '/EPSG:28992/{z}/{x}/{y}.png'"
          />
        </template>
      </template>


      <l-geo-json
          v-if="selectedFeature !== null"
          :geojson="selectedFeature"
          :options="options2">
      </l-geo-json>

      <l-geo-json
                  v-if="filteredFeatures.length > 0 && this.clusterZoom === 0"
                  :geojson="filteredFeatures"
                  :options="options">
      </l-geo-json>

      <l-control :position="'bottomleft'" class="custom-control-watermark">
        <switch-map-geo-novation :method="setVisibleMap" v-if="templateName === 'geonovation'"/>
        <switch-map-nijmegen :method="setVisibleMap" v-if="templateName === 'nijmegen'"/>
      </l-control>

      <v-marker-cluster :options="clusterOptions" v-if="clusterZoom > 0">
        <template v-if="filteredFeatures.length > 0 && settings.sub !== undefined && showMap">
          <template v-for="feature in filteredFeatures">
            <v-marker :options="markerOptions" v-if="feature.geometry !== null && feature.geometry.coordinates !== undefined" :lat-lng="convertLatLng(feature)" :key="'Point-' + feature.id">
              <l-icon
                  :icon-size="dynamicSize"
                  :icon-url="convertIconUrl(feature.properties)"
                  :className="convertIconClassName(feature.properties)"
              />
              <v-popup v-if="mapSettings !== undefined">
                <pop-up-map-geo-novation :feature="feature" v-if="templateName === 'geonovation'"/>
                <pop-up-map-nijmegen :feature="feature" v-if="templateName === 'nijmegen'"/>
              </v-popup>
            </v-marker>
          </template>
        </template>
      </v-marker-cluster>

      <v-marker v-if="geoLocation !== null" :lat-lng="[geoLocation.lat, geoLocation.lng]" :icon="iconGeoLocation">
        <v-popup>
          Huidige locatie
        </v-popup>
      </v-marker>

    </l-map>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {Icon, icon, latLng, LatLng, latLngBounds} from "leaflet";
import {
  LControl,
  LControlScale,
  LIcon,
  LIconDefault,
  LMap,
  LMarker,
  // LPolygon,
  LPopup,
  LTileLayer,
  LGeoJson,
  LWMSTileLayer
} from "vue2-leaflet"; //, LMarker, LPopup
import MapLeafletMarkerCluster from './MapLeafletMarkerCluser'
// import MapLeafletTitleLayer from "./MapLeafletTitleLayer";
import "proj4leaflet";
// import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
// import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import L from "leaflet";
import SwitchMapGeoNovation from "@/layout/GeoNovation/SwitchMapGeoNovation";
import SwitchMapNijmegen from "@/layout/Nijmegen/SwitchMapNijmegen";

import PopUpMapGeoNovation from "@/layout/GeoNovation/PopUpMapGeoNovation";
import PopUpMapNijmegen from "@/layout/Nijmegen/PopUpMapNijmegen";

const rdCrs = new window.L.Proj.CRS('EPSG:28992', '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs', {
      resolutions: [3440.640, 1720.320, 860.160, 430.080, 215.040, 107.520, 53.760, 26.880, 13.440, 6.720, 3.360, 1.680, 0.840, 0.420, 0.210, 0.105, 0.0525],
      bounds: window.L.bounds([-285401.92, 22598.08], [595402, 903402]),
      transformation: window.L.Transformation(-1, -1, 0, 0),
      origin: [-285401.920, 903401.920]
    }
)

export default {
  name: "MapLeafletMap",
  components: {
    PopUpMapNijmegen,
    PopUpMapGeoNovation,
    SwitchMapNijmegen,
    SwitchMapGeoNovation,
    LMap,
    LGeoJson,
    'v-marker': LMarker,
    'v-icondefault': LIconDefault,
    'v-popup': LPopup,
    // 'v-geo-json': LGeoJson,
    'v-marker-cluster': MapLeafletMarkerCluster,
    LTileLayer,
    // MapLeafletTitleLayer,
    LControlScale,
    'l-wms-tile-layer': LWMSTileLayer,
    LControl,
    // LPolygon,
    LIcon
  },
  data() {
    let iconGeoLocation = icon(Object.assign({},
        Icon.Default.prototype.options,
        {
          iconRetinaUrl: 'https://helpdesk.kaartviewer.nl/openiconen/03Sport/Eigen%20locatie.svg',
          iconUrl: 'https://helpdesk.kaartviewer.nl/openiconen/03Sport/Eigen%20locatie.svg',
          iconSize: [25, 25],
          shadowSize: [0, 0],
          'class-name': "someExtraClass"
        }
    ))
    return {
      iconUrl: 'https://helpdesk.kaartviewer.nl/openiconen/05Zorg/Advisering%20en%20ondersteuning.svg',
      zoom: 7,
      center: '',
      currentZoom: 11.5,
      activeLayerButtonIndex: 0,
      currentCenter: '',
      offsetMapHeight: '100%',
      offsetDivMapHeight: '',
      showParagraph: false,
      visibleMap: '',
      bounds: null,
      selectedFeature: null,
      fillColor: "#a80a2d",
      mapLoaded: false,
      featuresLoaded: false,
      showCluster: true,
      mapOptions: {
        zoomSnap: 0.5,
        preferCanvas: true,
      },
      crs: rdCrs,
      iconSize: 25,
      showMap: false,
      iconGeoLocation: iconGeoLocation,
      clusterOptions: {
        showCoverageOnHover: false,
        disableClusteringAtZoom: this.$store.getters.getClusterZoom,
        chunkedLoading: true,
        animate: true,
        zoomToBoundsOnClick: true,
        maxClusterRadius: 80
      },
      markerOptions: {
        riseOnHover: true,
        riseOffset: 500
      },
      geoLocationIsChanged: false,
      showWmsLayers: false
    };
  },
  computed: {
    layoutCombi() {
      return this.$store.getters.getLayoutCombi;
    },
    showFilteredFeatures() {
      return this.$store.getters.getShowFilteredFeatures;
    },
    clusterZoom() {
      return this.$store.getters.getClusterZoom;
    },
    filteredFeatures() {
      return this.$store.getters.getFilteredFeatures
    },
    features() {
      return this.$store.getters.getFeatures
    },
    mapSettings() {
      return this.$store.getters.getMap
    },
    detailSettings() {
      return this.$store.getters.getDetail
    },
    settings() {
      return this.$store.getters.getSettings
    },
    searchValue() {
      return this.$store.getters.getSearchValue
    },
    searchValueLocation() {
      return this.$store.getters.getSearchValueLocation
    },
    wmsLayers() {
      return this.$store.getters.getWmsLayers
    },
    getMapCenter() {
      return this.$store.getters.getMapCenter
    },
    getMapZoom() {
      return this.$store.getters.getMapZoom
    },
    templateName() {
      return this.$store.getters.getTemplateName
    },
    geoLocation() {
      return this.$store.getters.getGeoLocation
    },
    getMapBounds() {
      return this.$store.getters.getMapBounds
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize];
    },
    getActiveTab() {
      return this.$store.getters.getActiveTab
    },
    getActiveSubTab() {
      return this.$store.getters.getActiveSubTab
    },
    getActiveFeature() {
      return this.$store.getters.getActiveFeature
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
    fullScreen() {
      return this.$store.getters.getFullScreen
    },
    zoomToFilterResult() {
      return this.$store.getters.getZoomToFilterResult
    },
    custom_style() {

      let style = '';

      if(this.settings === undefined || this.settings.sub === undefined || this.settings.sub.length === 0) {
         return style;
      }

      style = '<style>';
      this.settings.sub.map((setting) => {
         style += '.sub-' + setting.id + ' { background-color:' + setting.wfs_style.fillColor + ' !important } ';
      });


      style +=    '.leaflet-marker-icon { background-color:#' + this.settings.sub[0].color + '  } ';
      style +=    '.marker-cluster-small div { background-color:#' + this.settings.sub[0].color + '  } ';
      style +=    '.marker-cluster-medium div { background-color:#' + this.settings.sub[0].color + '  } ';
      style +=    '.marker-cluster-large div { background-color:#' + this.settings.sub[0].color + '  } ';


      return style + '</style>';


    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },
    options2() {
      return {
        onEachFeature: this.onEachFeatureFunction2
      };
    },
    styleFunction() {

      // console.log(feature)

      const wfsStyle = this.settings.sub[0].wfs_style

      // console.log(feature.properties)
      const fillColor = wfsStyle.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: wfsStyle.strokeWidth,
          color: wfsStyle.strokeColor,
          opacity: wfsStyle.strokeOpacity,
          fillColor: fillColor,
          fillOpacity: wfsStyle.fillOpacity
        };
      };
    },

    onEachFeatureFunction2() {
      return (feature, layer) => {
        this.settings.sub.map( sub => {

          const mapSettings = this.mapSettings;
          const detailSettings = this.detailSettings;

          const title = feature.properties[mapSettings.records[0].attributes[0][0].Attribute]

          let toolTip = '<div class="popover"><h3 class="popover-header">' + title + '';

          // if(this.templateName === 'nijmegen') {
          //   toolTip += '<button class="mdi mdi-window-close md-toast-close-button" role="button">' +
          //       '  <span class="sr-only">Sluiten</span>' +
          //       '</button>';
          // }

          toolTip +=  '</h3>';
          toolTip += '<div class="popover-body">';

          toolTip += '<table class="gn-table">';
          toolTip += '    <caption class="gn-sr-only">';
          toolTip += '    Eigenschappen van ' + title;
          toolTip += '';
          toolTip += '</template>';
          toolTip += '</caption>';
          toolTip += '<tbody>';


          mapSettings.records[0].attributes.map( (attribute, index) => {

            // console.log(feature.properties[attribute[0].Attribute])

            if(parseInt(mapSettings.ShowEmptyFormAttributes) === 0 &&
                (
                    feature.properties[attribute[0].Attribute] === null ||
                    feature.properties[attribute[0].Attribute] === undefined
                )

            ) {
              index = 0
            }

            if(index > 0) {
              toolTip += '<tr>';
              toolTip += '<th>';
              toolTip += attribute[0].DisplayName;
              toolTip += '</th>';
              toolTip += '<td>';

              attribute.map((attributeItem) => {
                let value = feature.properties[attributeItem.Attribute];

                // console.log(value)

                if (value === null) {
                  value = '-';
                }

                // console.log(attributeItem.Type)

                if(attributeItem.Type === 21) {
                  if(feature.properties.kv.geometry !== undefined && !isNaN(feature.properties.kv.geometry.value)) {
                    toolTip += (feature.properties.kv.geometry.value / 1000).toFixed(1) + ' KM';
                  }
                } else {
                  toolTip += value;
                }
              });

              toolTip += '</td>';
              toolTip += '</tr>';
            }
          });

          toolTip += '</tbody>';
          toolTip += '</table>';

          if(detailSettings !== undefined) {
            toolTip += '<p class="card-read-more text-right mb-0 text-uppercase">';

            toolTip += '  <a href="?featureid=' + feature.id + '" class="btnReadMore" data-id="' + feature.id + '" aria-label="Lees meer over dossiernaam">';
            toolTip += ' Lees meer';
            toolTip += '  </a>';
            toolTip += '</p>';
          }

          toolTip += '</div>';


          toolTip += '</div>';

          layer.bindPopup(toolTip,
              { permanent: false, sticky: true }
          );

          const wfsStyle = sub.wfs_style
          if (feature.properties[sub.cqlkolom] === sub.cqlwaarde) {
            if (feature.geometry.type !== 'Point') {
              layer.setStyle({
                weight: wfsStyle.strokeWidth + 1,
                color: wfsStyle.strokeColor
              })
            }
          }
        });
      }
    },
    onEachFeatureFunction() {
      return (feature, layer) => {


        const mapSettings = this.mapSettings;
        const detailSettings = this.detailSettings;

        const title = feature.properties[mapSettings.records[0].attributes[0][0].Attribute]

        let toolTip = '<div class="popover"><h3 class="popover-header">' + title + '';

        // if(this.templateName === 'nijmegen') {
        //   toolTip += '<button class="mdi mdi-window-close md-toast-close-button" role="button">' +
        //   '  <span class="sr-only">Sluiten</span>' +
        //   '</button>';
        // }

        toolTip +=  '</h3>';
        toolTip += '<div class="popover-body">';

        toolTip += '<table class="gn-table">';
        toolTip += '    <caption class="gn-sr-only">';
        toolTip += '    Eigenschappen van ' + title;
        toolTip += '';
        toolTip += '</template>';
        toolTip += '</caption>';
        toolTip += '<tbody>';


        mapSettings.records[0].attributes.map( (attribute, index) => {

          // console.log(feature.properties[attribute[0].Attribute])

          if(parseInt(mapSettings.ShowEmptyFormAttributes) === 0 &&
              (
                  feature.properties[attribute[0].Attribute] === null ||
                  feature.properties[attribute[0].Attribute] === undefined
              )

          ) {
              index = 0
          }

          if(index > 0) {
            toolTip += '<tr>';
            toolTip += '<th>';
            toolTip += attribute[0].DisplayName;
            toolTip += '</th>';
            toolTip += '<td>';

            attribute.map((attributeItem) => {
              let value = feature.properties[attributeItem.Attribute];

              // console.log(value)

              if (value === null) {
                value = '-';
              }

              // console.log(attributeItem.Type)

              if(attributeItem.Type === 21) {
                if(feature.properties.kv.geometry !== undefined && !isNaN(feature.properties.kv.geometry.value)) {
                  toolTip += (feature.properties.kv.geometry.value / 1000).toFixed(1) + ' KM';
                }
              } else {
                toolTip += value;
              }
            });

            toolTip += '</td>';
            toolTip += '</tr>';
          }
        });

        toolTip += '</tbody>';
        toolTip += '</table>';

        if(detailSettings !== undefined) {
          toolTip += '<p class="card-read-more text-right mb-0 text-uppercase">';

          toolTip += '  <a href="?featureid=' + feature.id + '" class="btnReadMore" data-id="' + feature.id + '" aria-label="Lees meer over dossiernaam">';
          toolTip += ' Lees meer';
          toolTip += '  </a>';
          toolTip += '</p>';
        }

        toolTip += '</div>';


        toolTip += '</div>';

        layer.bindPopup(toolTip,
            { permanent: false, sticky: true }
        );

        // console.log(this.settings.sub)

        this.settings.sub.map( sub => {

          const wfsStyle = sub.wfs_style

          // console.log(sub.cqlwaarde)

          if(sub.cqlwaarde == null || sub.cqlwaarde === "") {

            if(feature.geometry.type === 'Point') {

              if(wfsStyle.externalGraphic !== "") {
                layer.setIcon(L.icon({
                  iconUrl: wfsStyle.externalGraphic,
                }))
              }
            } else {
              layer.setStyle({
                weight: wfsStyle.strokeWidth,
                color: wfsStyle.strokeColor,
                opacity: wfsStyle.strokeOpacity,
                fillColor: wfsStyle.fillColor,
                fillOpacity: wfsStyle.fillOpacity
              })
            }

          } else if(feature.properties[sub.cqlkolom] === sub.cqlwaarde) {


            if(feature.geometry.type === 'Point') {
                if(wfsStyle.externalGraphic !== "") {
                  layer.setIcon(L.icon({
                    iconUrl: wfsStyle.externalGraphic,
                  }))
                }
            } else {
              layer.setStyle({
                weight: wfsStyle.strokeWidth,
                color: wfsStyle.strokeColor,
                opacity: wfsStyle.strokeOpacity,
                fillColor: wfsStyle.fillColor,
                fillOpacity: wfsStyle.fillOpacity
              })
            }
          }

        });

      };
    },
    createCustomIcon (feature, latlng) {
      let myIcon = L.icon({
        iconUrl: 'https://helpdesk.kaartviewer.nl/openiconen/999fontawesome/head-side-mask.svg',
        shadowUrl: 'https://helpdesk.kaartviewer.nl/openiconen/999fontawesome/head-side-mask.svg',
        iconSize:     [25, 25], // width and height of the image in pixels
        shadowSize:   [35, 20], // width, height of optional shadow image
        iconAnchor:   [12, 12], // point of the icon which will correspond to marker's location
        shadowAnchor: [12, 6],  // anchor point of the shadow. should be offset
        popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
      })
      return L.marker(latlng, { icon: myIcon })
    }
  },
  methods: {
    zoomToFilterSearchResult() {
        const bounds = [];

        this.filteredFeatures.map((feature) => {
          if(feature.geometry !== null && feature.geometry.coordinates !== undefined) {
            bounds.push(L.geoJSON(feature).getBounds())
          }
        });

        if(bounds.length > 0) {
          setTimeout(() => {
            this.$refs.map.mapObject.flyToBounds(bounds, {
              'maxZoom': 12
            })
          }, 1000)

        }

        console.log('filteredFeatures')
        console.log(this.filteredFeatures)
    },
    convertZIndex(sorting) {
      return sorting + 2
    },
    zoomIn() {
      this.$refs.map.mapObject.zoomIn()
    },
    zoomOut() {
      this.$refs.map.mapObject.zoomOut()
    },
    setVisibleMap(visibleMap) {
      this.visibleMap = visibleMap
    },
    handleClick(e) {
      //console.log(e.target)

      e.preventDefault();
      if (e.target.matches('.btnReadMore')) {

        console.log('read moreee')
        const selectedFeature = this.filteredFeatures.find( (feature) => {
            return feature.id === e.target.dataset.id
        });

        this.setActiveTab(e, 'list', selectedFeature, 'detail')
      }
    },
    testClick() {
        //console.log('test')
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    boundsUpdated (bounds) {
      this.bounds = bounds;
      //console.log(bounds)
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    setActiveTab(e, activeTab, feature, activeSubTab) {
      e.preventDefault();
      this.activeTab = activeTab;
      this.$store.commit('setActiveTab', activeTab);
      this.$store.commit('setActiveSubTab', activeSubTab);
      this.$store.commit('setActiveFeature', feature);
    },
    // convertLatLng(geometry) {
    convertLatLng(feature) {
      return L.geoJSON(feature).getBounds().getCenter();
    },
    convertIconUrl(properties) {

      let icon = 'https://helpdesk.kaartviewer.nl/openiconen/03Sport/Eigen%20locatie.svg';
      // if(this.settings.sub !== undefined) {
      //   this.settings.sub.map((setting) => {
      //     if ( (properties[setting.cqlkolom] === setting.cqlwaarde) || (setting.cqlwaarde === null && setting.icon !== '')) {
      //       icon = setting.icon.replace('${{' + setting.cqlkolom + '}}', properties[setting.cqlkolom]);
      //     }
      //   });
      // }

      this.settings.sub.map( sub => {

        const wfsStyle = sub.wfs_style

        if(sub.cqlwaarde == null || sub.cqlwaarde === "") {
            if(wfsStyle.externalGraphic !== "") {
              icon = wfsStyle.externalGraphic
            }

        } else if(properties[sub.cqlkolom] === sub.cqlwaarde) {
              icon = wfsStyle.externalGraphic
        }

      });


      return icon;
      // return this.settings.sub[0].icon.replace('${{kaart}}', properties['kaart']);
    },
    convertIconClassName(properties) {
      let className = 'empty';
      if(this.settings.sub !== undefined) {
        this.settings.sub.map((setting) => {
          if (properties[setting.cqlkolom] == setting.cqlwaarde) {
              className = 'sub-' + setting.id;
          }
        });
      }

      return className;
      // return this.settings.sub[0].icon.replace('${{kaart}}', properties['kaart']);
    },
    convertSetting(properties, settingValue) {

      let wfsStyle = null;
      if(this.settings.sub !== undefined) {
          this.settings.sub.map((setting) => {
            if (properties[setting.cqlkolom] === setting.cqlwaarde) {
              wfsStyle = setting.wfs_style;
            }
          });
      }

        switch (settingValue) {
          case 'fillColor':
            if(wfsStyle !== null) {
              return wfsStyle.fillColor;
            }
            return '';
          case 'color':
            if(wfsStyle !== null) {
              return wfsStyle.strokeColor;
            }
            return '';
          case 'opacity':
            if(wfsStyle !== null) {
              return wfsStyle.strokeOpacity;
            }
            return 0;
          case 'weight':
            if(wfsStyle !== null) {
              return wfsStyle.strokeWidth;
            }
            return 0;
          case 'fillOpacity':
            if(wfsStyle !== null) {
              return wfsStyle.fillOpacity;
            }
            return 0;
        }

        return '';
    },
    // tileLayerOptions(setting) {
    tileLayerOptions(layer, transparent = false) {

      let options = {}

      options.continuousWorld = true
      options.transparent = transparent

      if(layer !== null) {
        options.minZoom = this.calc_Zoom(layer.minscale)
        options.maxZoom = this.calc_Zoom(layer.maxscale)
      }

      return options;
    },
    calc_Zoom(scale) {
      if (scale < 250) {
        return (16);
      }
      if (scale < 500) {
        return (15);
      }
      if (scale < 1000) {
        return (14);
      }
      if (scale < 2000) {
        return (13);
      }
      if (scale < 3000) {
        return (12);
      }
      if (scale < 5000) {
        return (11);
      }
      if (scale < 10000) {
        return (10);
      }
      if (scale < 20000) {
        return (9);
      }
      if (scale < 40000) {
        return (8);
      }
      if (scale < 80000) {
        return (7);
      }

      // if (scale <= 1000001) {
        return (1);
      // }
    },
    btnShowBackgroundLayer() {

      let showBackgroundLayer = true;


      // const layers = [];
      // let selectedIndex = 0;
      // this.settings.achtergrondkaarten.map( (value, index) => {
      //   if(value.naam === layer.naam) {
      //     layers.push(layer);
      //   }
      //   if(value.id === layer.id) {
      //     selectedIndex = index;
      //   }
      // });
      //
      //   if(layers.length > 1 && selectedIndex === 0) {
      //     showBackgroundLayer = false;
      //   }

      return showBackgroundLayer;
    },
    getActiveFeatureLatLng() {
      const feature = this.getActiveFeature

      //console.log(feature)

      if(feature !== undefined && feature !== null && feature.geometry !== null && feature.geometry !== undefined && feature.geometry.coordinates !== undefined) {

        let latLng;

        const geometry = feature.geometry;
        const coordinates = geometry.coordinates;

        switch(geometry.type) {
          case 'Point':
            latLng = L.latLng(coordinates[1], coordinates[0]);
            break;
          default:
            if(coordinates[0][0][0] !== undefined && Array.isArray(coordinates[0][0][0])) {
              // //console.log(coordinates[0][0][0]);
              latLng = L.latLng(coordinates[0][0][0][1], coordinates[0][0][0][0]);
            } else if(coordinates[0][0][0] !== undefined && Array.isArray(coordinates[0][0][0])) {
              latLng = L.latLng(coordinates[0][0][1], coordinates[0][0][0]);
            } else {
              latLng = L.latLng(coordinates[0][1], coordinates[0][0]);
            }
        }
        return latLng
      }
    },
    zoomToActiveFeature() {

      const feature = this.getActiveFeature

      console.log('zoomToActiveFeature')

      if (feature !== undefined && feature !== null && feature.geometry !== null && feature.geometry !== undefined && feature.geometry.coordinates !== undefined) {
        this.selectedFeature = feature
        this.$refs.map.mapObject.flyTo(L.geoJSON(feature).getBounds().getCenter(), 12)
      } else {
        if(this.searchValueLocation !== '') {
          const geoLocation =  this.geoLocation
          this.$refs.map.mapObject.flyTo(new LatLng(geoLocation.lat, geoLocation.lng), 12)
        }
        else if(this.searchValue !== '') {
            this.zoomToFilterSearchResult()
        }
        else {
          this.$refs.map.mapObject.flyToBounds(this.getMapBounds)
        }
      }
    },
    showWfsLegend() {

      let hasLegend = false
      this.settings.sub.map((layer) => {
        // console.log(layer)
          if(layer.wfs_style.LegendImage !== '') {
            hasLegend = true
          }
      });

      return hasLegend
    },
    wfsLegendBorderRadius() {
      if(this.clusterZoom > 0) {
          return '50%'
      }
      return 'unset'
    }
  },
  mounted() {
      // const map = this.$refs.map.mapObject;
      // map.addControl(new Leaflet.Control.Fullscreen());

      const myVar2 = setInterval(() => {
      if (this.mapLoaded === true) {
        this.showMap = true
        this.showWmsLayers = true

        if(this.searchValue !== '') {
          this.zoomToFilterSearchResult()
        }

        clearInterval(myVar2);
      }
    }, 1000);
  },
  created() {
    var myVar = setInterval(() => {
      if (this.$refs.map !== undefined) {
        const map = this.$refs.map.mapObject;

        map.zoomControl.remove()

        this.visibleMap = this.settings.achtergrondkaarten[0].naam
        this.mapLoaded = true
        clearInterval(myVar);

        map.on('zoomend', () => {
          // console.log('invalidateSize')
           map.invalidateSize()

          if(this.filteredFeatures !== undefined) {
            // console.log(this.filteredFeatures.length)
          }
          //
          // console.log(map)
          // console.log(map._layers)
          // console.log(map._layers['4650'])
          //
          // yourArray.forEach(function (arrayItem) {
          //
          //   console.log(x);
          // });

          let counter = 1

          for (var prop in map._layers) {

            if (6 === counter){
              console.log(prop)
              // console.log(map._layers[prop])

              // map._layers[prop].bringToBack()
            }
            counter++;
          }
        });

      }
    }, 300);

    // this.options.pointToLayer = this.createCustomIcon
  },
  watch: {
    clusterZoom(value) {
      this.clusterOptions = {
          showCoverageOnHover: false,
            disableClusteringAtZoom: value,
            chunkedLoading: true,
            animate: true,
            zoomToBoundsOnClick: true
      }
    },
    geoLocation(geoLocation) {
      this.geoLocationIsChanged = true

      if(geoLocation !== null) {
        this.$refs.map.mapObject.flyTo(new LatLng(geoLocation.lat, geoLocation.lng), 12)
      }
    },
    getMapCenter(mapCenter) {

      if (mapCenter !== undefined && this.$refs.map !== undefined) {

        this.$refs.map.mapObject.flyTo(mapCenter, 12)
      }
    },
    getActiveSubTab(activeSubTab) {
      if(activeSubTab === 'detail' && this.layoutCombi === 1) {
        this.zoomToActiveFeature()
      }
    },
    getActiveTab(activeTab) {

      console.log('getActiveTab')

      if(activeTab === 'map' && this.$refs.map !== undefined) {
        this.zoomToActiveFeature()

        this.$refs.map.mapObject.invalidateSize()
        // console.log('redraw')
      }
    },

    // filteredFeatures(filteredFeatures) {
    filteredFeatures(filteredFeatures) {

      // console.log('test')
      // console.log(filteredFeatures)

      if(this.layoutCombi !== 1) {
        this.featuresLoaded = true
      }

      this.selectedFeature = null

      // console.log(this.zoomToFilterResult)
      //
      // console.log(filteredFeatures.length)
      // console.log(this.features.length)

      if(filteredFeatures.length === this.features.length) {
        // Do nothing: it will go to the changed geolocation

        const geoLocation = this.geoLocation
        if(this.geoLocationIsChanged && geoLocation !== null) {

          this.$refs.map.mapObject.flyTo(new LatLng(geoLocation.lat, geoLocation.lng), 12)

          this.geoLocationIsChanged = false
        } else {
          this.$refs.map.mapObject.flyToBounds(this.getMapBounds)
        }

      } else if (this.$refs.map !== undefined && this.mapLoaded && this.featuresLoaded === true && this.zoomToFilterResult > 0) {
        const bounds = [];

        filteredFeatures.map((feature) => {
          if(feature.geometry !== null && feature.geometry.coordinates !== undefined) {
            bounds.push(L.geoJSON(feature).getBounds())
          }
        });

        if(bounds.length > 0) {
          this.$refs.map.mapObject.flyToBounds(bounds, {
             'maxZoom': 12
          })
        }
      } else {
        this.featuresLoaded = true
      }
    },
    fullScreen(value) {
        if(value) {
            this.offsetMapHeight = 'calc(100vh - 250px)'
            this.offsetDivMapHeight = 'height: 100%'
        } else {
            this.offsetMapHeight = '100%'
            this.offsetDivMapHeight = ''
        }

      this.$refs.map.mapObject.invalidateSize()

      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize()
      }, 600);
      // console.log('resize')
    }
  }
};
</script>

<style>

.leaflet-marker-icon {
  border-radius: 50%;
  margin-left: -14px;
  margin-top: -14px;
  width: 28px;
  height: 28px;
  transform: translate3d(834px, 401px, 0px);
  z-index: 401;
  outline: currentcolor none medium;
}

.marker-cluster-small, .marker-cluster-medium, .marker-cluster-large {
  color: white;
  font-size: 11px;
  font-weight: bold;
}

.leaflet-popup-content {
  margin: 0 !important;
  width: 250px !important;
}


.example-custom-control {
  background: #fff;
  padding: 0 0.5em;
  border: 1px solid #aaa;
  border-radius: 0.1em;
}

.custom-control-watermark {
  background-color: hsla(0, 0%, 100%, .9) !important;
}

.leaflet-control-attribution leaflet-control {
  display: none;
}

.img-legend {
  margin-right: 18px;
}

.custom-control-legend {
  background-color: hsla(0, 0%, 100%, .7) !important;
}
</style>
