<template>
  <div role="search" id="gn-form-search-location" @submit="handleFormSubmit">
    <autocomplete ref="autocompleteLocation"
                  :get-result-value="getResultValue"
                  :search="searchLocation"
                  :debounce-time="500"
                  @submit="onSubmitLocation"
    >

      <template
          #default="{
      rootProps,
      inputProps,
      inputListeners,
      resultListProps,
      resultListListeners,
      results,
      resultProps
    }"
      >
        <div v-bind="rootProps">
          <label for="gn-locationsearchinput" class="gn-sr-only">Verfijn je resultaat</label>
          <input
              type="text"
              v-bind="inputProps"
              v-on="inputListeners"
              ref="locationsearchinput"
              id="gn-locationsearchinput"
              placeholder="Zoek in de buurt van"
              @keyup="onkeyupLocation"
              autocomplete="off"
              aria-controls="autocomplete-results"
              aria-autocomplete="both"
              aria-label="Zoekveld"
              aria-describedby="autocomplete-help-text-filter"
          />
          <button v-on:click="askForLocation" class="gn-currentlocation">
            <span class="gn-text">Locatie ophalen</span>
            <span aria-hidden="true" class="gn-icon gn-icon-search"></span>
            <span class="gn-sr-only">Haal door middel van uw gps de locatie up</span>
          </button>

          <button v-if="keyUpLocationValue !== ''" v-on:click="clearLocation" class="gn-locationsearch-clear">
            <span class="gn-text">Locatie zoeken leegmaken</span>
            <span aria-hidden="true" class="gn-icon gn-icon-filter-location"></span>
          </button>

          <button v-if="keyUpLocationValue === ''" v-on:click="clearLocation" class="gn-locationsearch">
            <span class="gn-text">Locatie zoeken</span>
            <span aria-hidden="true" class="gn-icon gn-icon-filter-location-clear"></span>
          </button>

          <button v-if="keyUpLocationValue === ''" v-on:click="toggleFullScreen" class="gn-fullscreen">
            <span class="gn-text">
              Volledig scherm <template v-if="fullScreen">sluiten</template>
            </span>
            <span aria-hidden="true" class="gn-icon gn-icon-full-screen"></span>
            <span aria-hidden="true" class="gn-icon gn-icon-cancel-full-screen"></span>
          </button>

          <ul id="gn-locationsearch-results" aria-label="Zoekresultaten" role="listbox" v-bind="resultListProps" v-on="resultListListeners">
            <li aria-hidden="true">Zoekresultaten:</li>
            <li
                role="option"
                v-for="(result, index) in results"
                :key="resultProps[index].value"
                v-bind="resultProps[index]"
            >
              <a
                  @click="onClick"
                  :href="getUrl([
                         { name: 'q', value: result },
                       ])"
                  ref="autocompletelinklocation" tabindex="-1">{{ result.value }}</a>
            </li>
          </ul>

          <span id="autocomplete-help-text-filter" class="gn-sr-only">
              Als er zoekresultaten gevonden zijn kunt u de pijltjes toetsen omhoog en naar beneden gebruiken om een resultaat
              te selecteren. Toets enter om te navigeren naar het geselecteerde resultaat. Met de escape toets kunt u de
              invoer wissen.
            </span>
        </div>
      </template>
    </autocomplete>
  </div>
</template>

<script>

import SearchLocationMixin from "@/mixins/SearchLocationMixin";
import Autocomplete from "@trevoreyre/autocomplete-vue";

export default {
  name: "SearchLocationGeoNovation",
  mixins: [
    SearchLocationMixin,
  ],
  components: {
    Autocomplete
  },
  computed: {
    features() {
      return this.$store.getters.getFeatures
    },
    filteredFeatures() {
      return this.$store.getters.getFilteredFeatures
    },
    kaartViewerUrl() {
      return this.$store.getters.getKaartViewerUrl
    },
    settings() {
      return this.$store.getters.getSettings
    },
    fullScreen() {
      return this.$store.getters.getFullScreen
    },
    previousActiveTab() {
      return this.$store.getters.getPreviousActiveTab
    }
  },
  methods: {
    searchLocation(input) {

      console.log(input)

      const cbsCodes = this.settings.gemeente.code
      const url = this.kaartViewerUrl + `/admin/rest/routeapi/location?cbsCodes=${cbsCodes}&query=${encodeURI(input)}`

      return new Promise((resolve) => {
        if (input.length < 3) {
          return resolve([])
        }

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
              resolve(data.suggestions)
            })
      })
    },
    onSubmitLocation(result) {

      console.log(result)

      if(result === undefined) {
        return;
      }

      this.$store.commit('setSearchValueLocation', result)
      this.$store.commit('setActiveFeature', {});
      this.$store.commit('isMapMounted', true);
      this.$store.commit('setActiveSubTab', '')
      this.$store.commit('setActiveTab', this.previousActiveTab)
      this.searchForLocation(result)

      this.keyUpLocationValue = this.getResultValue(result)

      this.searchLocationValue = result

      // this.$store.commit('setSearchValue', '')
      // document.getElementById('gn-filterinput').value = ''
    },
    getResultValue(result) {
      return result.value
    },
    setValue(value) {

      const feature = {}
      feature.value = value

      this.$refs.autocompleteLocation.setValue(feature)
    },
    onClick(e) {
      e.preventDefault()
    },
    onkeyupLocation(result) {

      console.log('onkeyupLocation')

      console.log(result)
      if(result.keyCode === 13) {

        // result.preventDefault();

        this.$refs.locationsearchinput.blur();
        this.$refs.locationsearchinput.focus()

        return;
      }

      console.log('onkeyupLocation after enter')

      this.$store.commit('setSearchValueLocation', result)
      if(result.target.value === '') {
        this.keyUpLocationValue = ''
      } else {
        this.keyUpLocationValue = result.target.value
      }

      if(this.$refs.autocompletelinklocation !== undefined) {
        this.$refs.autocompletelinklocation.map((element) => {
          if(
              element.parentElement !== null && element.parentElement.getAttribute('aria-selected') === 'true')
          {
            //console.log('make active')
            element.classList.value = ['gn-autocomplete-result-link active']
          } else {
            element.classList.value = ['gn-autocomplete-result-link']
          }
        });
      }
    },
    toggleFullScreen() {
        this.$store.commit('setFullScreen', !this.fullScreen)
    },
    clearLocation(e) {
      e.preventDefault()
      this.keyUpLocationValue = ''
      this.$refs.autocompleteLocation.setValue('')
      this.$store.commit('setActiveFilters', [])

      this.filteredFeatureCopy = this.filteredFeatures
      this.filteredFeatureCopy.map(feature => {
        this.addDistance(feature, null)
      });
      this.$store.commit('setFilteredFeatures', this.features)
      this.$store.commit('setGeoLocation', null);
    }
  }
};
</script>