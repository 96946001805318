<template>
  <table class="gn-table">
<!--    <caption class="gn-sr-only">Eigenschappen-->
<!--      <template v-for="settingItem in getAttributes()[0]">-->
<!--          {{ getFeatureValue(settingItem) }}-->
<!--      </template>-->
<!--    </caption>-->
    <tbody>
    <template v-for="(setting, index) in getAttributes()">
      <template v-if="formSettings.ShowEmptyFormAttributes === 0">
        <template v-for="(settingItem) in setting">
            <template v-if="getFeatureValue(settingItem) !== null && getFeatureValue(settingItem) !== ''">
              <tr v-bind:key="settingItem.ID" v-if="parseInt(settingItem.Type) !== 21 && ( ( ( type === 'list' || type === 'map') && index > 0) || (type === 'detail') )">
                  <th v-if="settingItem.DisplayName !== ''">
                    {{ settingItem.DisplayName }}
                  </th>
                  <td :colspan="settingItem.DisplayName === '' ? '2': '1'">
                    <template v-if="parseInt(settingItem.Type) === 0">
                      {{settingItem.Prefix}}{{ getFeatureValue(settingItem) }}{{settingItem.Suffix}}
                    </template>
                    <template v-else-if="parseInt(settingItem.Type) === 1">
                      <div class="row">
                        <template v-if="isImage(settingItem.Suffix, feature.properties[settingItem.Attribute])">
                          <template v-if="settingItem.FileStreamed">
                            <img class="col-md-12" style="display: block" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + formSettings.DomainID + '/presentation/' + formSettings.PresentationID + '/formattribute/' + settingItem.FormAttributeID + '/item/0/filename/.jpg?fileName=' + feature.properties[settingItem.Attribute] + settingItem.Suffix" :title="settingItem.Prefix + feature.properties[settingItem.Attribute] + settingItem.Suffix">
                          </template>
                          <template v-else>
                            <img class="col-md-12" style="display: block" :src="settingItem.Prefix + getFeatureValue(settingItem) + settingItem.Suffix" :title="settingItem.Prefix + getFeatureValue(settingItem) + settingItem.Suffix">
                          </template>
                        </template>
                        <template v-else>
                          <a v-bind:key="settingItem.ID" class="open_link" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + formSettings.DomainID + '/presentation/' + formSettings.PresentationID + '/formattribute/' + settingItem.FormAttributeID + '/item/0/filename/' + feature.properties[settingItem.Attribute] + settingItem.Suffix + '?fileName=' + feature.properties[settingItem.Attribute] + settingItem.Suffix" target="_blank">
                            Open link
                          </a>
                        </template>
                      </div>
                    </template>
                    <template v-else-if="parseInt(settingItem.Type) === 3">
                      <a v-on:click="openLink(settingItem.Prefix + getFeatureValue(settingItem) + settingItem.Suffix)" target="_blank" :href="settingItem.Prefix + getFeatureValue(settingItem) + settingItem.Suffix">{{ getFeatureValue(settingItem) }}{{settingItem.Suffix}}</a>
                    </template>
                    <template v-else-if="parseInt(settingItem.Type) === 402">
                      <div v-html="getFeatureValue(settingItem)"></div>
                    </template>
                    <template v-else-if="parseInt(settingItem.Type) === 404">
                      <div v-html="settingItem.Prefix + getFeatureValue(settingItem) + settingItem.Suffix"></div>
                    </template>
                  </td>
                </tr>
            </template>
          </template>
      </template>
      <template v-else>
      <tr v-bind:key="setting[0].ID" v-if="parseInt(setting[0].Type) !== 21 && ( ( ( type === 'list' || type === 'map') && index > 0) || (type === 'detail') )">
        <th v-if="setting[0].DisplayName !== ''">
          {{ setting[0].DisplayName }}
        </th>
        <template v-for="settingItem in setting">
              <td :colspan="settingItem.DisplayName === '' ? '2': '1'" v-bind:key="settingItem.ID">
                <template v-if="parseInt(settingItem.Type) === 0">
                  {{settingItem.Prefix}}{{ getFeatureValue(settingItem) }}{{settingItem.Suffix}}
                </template>
                <template v-else-if="parseInt(settingItem.Type) === 1">
                  <div class="row">
                    <template v-if="isImage(settingItem.Suffix, feature.properties[settingItem.Attribute])">
                      <template v-if="settingItem.FileStreamed">
                        <img class="col-md-12" style="display: block" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + formSettings.DomainID + '/presentation/' + formSettings.PresentationID + '/formattribute/' + settingItem.FormAttributeID + '/item/0/filename/.jpg?fileName=' + feature.properties[settingItem.Attribute] + settingItem.Suffix" :title="settingItem.Prefix + feature.properties[settingItem.Attribute] + settingItem.Suffix">
                      </template>
                      <template v-else>
                        <img class="col-md-12" style="display: block" :src="settingItem.Prefix + getFeatureValue(settingItem) + settingItem.Suffix" :title="settingItem.Prefix + getFeatureValue(settingItem) + settingItem.Suffix">
                      </template>
                    </template>
                    <template v-else>
                      <a v-bind:key="settingItem.ID" class="open_link" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + formSettings.DomainID + '/presentation/' + formSettings.PresentationID + '/formattribute/' + settingItem.FormAttributeID + '/item/0/filename/' + feature.properties[settingItem.Attribute] + settingItem.Suffix + '?fileName=' + feature.properties[settingItem.Attribute] + settingItem.Suffix" target="_blank">
                        Open link
                      </a>
                    </template>
                  </div>
                </template>
                <template v-else-if="parseInt(settingItem.Type) === 3">
                  <a v-on:click="openLink(settingItem.Prefix + getFeatureValue(settingItem) + settingItem.Suffix)" target="_blank" :href="settingItem.Prefix + getFeatureValue(settingItem) + settingItem.Suffix">{{ getFeatureValue(settingItem) }}{{settingItem.Suffix}}</a>
                </template>
                <template v-else-if="parseInt(settingItem.Type) === 402">
                  <div v-html="getFeatureValue(settingItem)"></div>
                </template>
                <template v-else-if="parseInt(settingItem.Type) === 404">
                  <div v-html="settingItem.Prefix + getFeatureValue(settingItem) + settingItem.Suffix"></div>
                </template>
              </td>
        </template>
      </tr>
      </template>
    </template>
    </tbody>
  </table>
</template>

<script>
import TableMixin from "@/mixins/TableMixin";

export default {
  name: "TableGeoNovation",
  mixins: [
    TableMixin,
  ],
  methods: {
    openLink(url) {
        window.open(url, '_blank');
    },
    getAttributes() {
        if (this.formSettings.records[0] !== undefined) {
          return this.formSettings.records[0].attributes
        }
        return this.formSettings.attributes
    },
    getFeatureValue(settingItem) {
        if(this.feature !== null) {
          return this.feature.properties[settingItem.Attribute]
        }
        return settingItem.Value;
    }
  }
};
</script>


