<template>
  <div class="tabs">
      <search-location-geo-novation v-if="hideLocationSearch === 0"/>

      <div role="tablist" aria-label="Soorten representatie" @keydown="clickKey">
          <template v-if="mapFirst === 0">
            <switch-component @eventname="setFocusTab" v-bind:focus-tab="focusTab" v-bind:data-index="'0'" v-bind:tab-index="''" v-bind:id="'gn-list'" v-bind:tab-panel-id="'gn-list-tab'" v-bind:name="'Lijst'" v-bind:element="'list'"/>
            <switch-component @eventname="setFocusTab" v-bind:focus-tab="focusTab" v-bind:data-index="'1'" v-bind:tab-index="'-1'" v-bind:id="'gn-map'" v-bind:tab-panel-id="'gn-map-tab'" v-bind:name="'Kaart'" v-bind:element="'map'"/>
          </template>
          <template v-else>
            <switch-component @eventname="setFocusTab" v-bind:focus-tab="focusTab" v-bind:data-index="'1'" v-bind:tab-index="'-1'" v-bind:id="'gn-map'" v-bind:tab-panel-id="'gn-map-tab'" v-bind:name="'Kaart'" v-bind:element="'map'"/>
            <switch-component @eventname="setFocusTab" v-bind:focus-tab="focusTab" v-bind:data-index="'0'" v-bind:tab-index="''" v-bind:id="'gn-list'" v-bind:tab-panel-id="'gn-list-tab'" v-bind:name="'Lijst'" v-bind:element="'list'"/>
          </template>
      </div>

      <div tabindex="0" role="tabpanel" id="gn-list-tab" aria-labelledby="gn-list" v-bind:hidden="activeTab !== 'list'" v-show="activeTab === 'list'">
          <div id="gn-list-details" v-bind:hidden="activeSubTab !== 'detail'" v-if="activeSubTab === 'detail'">
            <detail-geo-novation/>
          </div>
          <div id="gn-list" v-bind:hidden="activeSubTab === 'detail'" v-show="activeSubTab !== 'detail'">
            <list-geo-novation/>
          </div>
      </div>

      <div tabindex="0" role="tabpanel" id="gn-maps-tab" aria-labelledby="gn-map" v-bind:hidden="activeTab !== 'map'" v-show="activeTab === 'map'">
        <template v-if="isMapMounted === true">
          <map-view/>
        </template>
      </div>
  </div>
</template>

<script>

import MapView from "@/views/MapView";
import ListGeoNovation from "@/layout/GeoNovation/ListGeoNovation";
import SearchLocationGeoNovation from "@/layout/GeoNovation/SearchLocationGeoNovation";
import DetailGeoNovation from "@/layout/GeoNovation/DetailGeoNovation";
import SwitchMixin from "@/mixins/SwitchMixin";

export default {
  name: "SwitchGeoNovation",
  mixins: [
    SwitchMixin,
  ],
  components: {
    DetailGeoNovation,
    SearchLocationGeoNovation,
    ListGeoNovation,
    MapView
  },
  computed: {
    activeTab() {
      return this.$store.getters.getActiveTab
    },
    mapFirst() {
      return this.$store.getters.getMapFirst
    },
    activeSubTab() {
      return this.$store.getters.getActiveSubTab
    },
    isMapMounted() {
      return this.$store.getters.isMapMounted;
    },
    hideLocationSearch() {
      return this.$store.getters.getHideLocationSearch
    }
  }
}
</script>