<template>
    <form-detail/>
</template>

<script>
    import FormDetail from "../components/form/FormDetail";

    export default {
        name: "DetailView",
        components: {
            FormDetail
        }
    }
</script>

<style scoped>

</style>
