export const map = {
    state: {
        mapCenter: '',
        mapDefaultCenter: '',
        mapZoom: 7,
        clusterZoom: 16,
        wmsLayers: [],
        mapBounds: null,
        mapMounted: false
    },
    getters: {
        getMapCenter: state => {
            return state.mapCenter
        },
        getMapDefaultCenter: state => {
            return state.mapDefaultCenter
        },
        getMapZoom: state => {
            return state.mapZoom
        },
        getClusterZoom: state => {
            return state.clusterZoom
        },
        getWmsLayers: state => {
            return state.wmsLayers
        },
        getMapBounds: state => {
            return state.mapBounds
        },
        isMapMounted: state => {
            return state.mapMounted
        },
    },
    mutations: {
        setMapZoom(state, zoom) {
            state.mapZoom = zoom
        },
        setMapCenter(state, center) {
            state.mapCenter = center
        },
        setDefaultMapCenter(state, center) {
            state.mapDefaultCenter = center
        },
        setClusterZoom(state, center) {
            state.clusterZoom = parseInt(center)
        },
        setWmsLayers(state, wmsLayers) {
            state.wmsLayers = wmsLayers
        },
        setMapBounds(state, mapBounds) {
            state.mapBounds = mapBounds
        },
        isMapMounted(state, mapMounted) {
            state.mapMounted = mapMounted
        },
    }
}
