import SwitchComponent from "@/components/switch/SwitchComponent";

export default {
    components: {
        SwitchComponent
    },
    data() {
        return {
            focusTab: 'list'
        }
    },
    mounted() {
        // setTimeout(() => {
        this.mounted = 1
        // }, 300);
    },
    methods: {
        setFocusTab(focusTab) {
            this.focusTab = focusTab
        },
        clickKey(event) {
            if(event.keyCode == 37) {
                this.focusTab = 'list'
            } else if(event.keyCode == 39) {
                this.focusTab = 'map'
            } else {
                this.focusTab = ''
            }
        }
    }
}
