<template>
    <map-leaflet/>
</template>

<script>

    import MapLeaflet from "../components/mapleaflet/MapLeaflet";

    export default {
        name: "MapView",
        components: {
            MapLeaflet,
        }
    }
</script>

<style scoped>

</style>
