<template>
  <div class="popover">
    <h3 class="popover-header">
      <template v-for="settingItem in mapSettings.records[0].attributes[0]">
        {{feature.properties[settingItem.Attribute]}}
      </template>
    </h3>
    <div class="popover-body">
      <table-geo-novation
          v-bind:feature="feature"
          v-bind:form-settings="mapSettings"
          v-bind:type="'map'"
      ></table-geo-novation>
      <template v-if="detailSettings !== undefined">
          <p class="card-read-more text-right mb-0 text-uppercase">
            <a class="btnReadMore"
                :href="getUrl([
                     { name: 'featureid', value: feature.id },
                   ])"
                :data-id="feature.id" :aria-label="generateAriaLabel(feature, mapSettings.records[0].attributes[0])">
              Lees meer
            </a>
          </p>
      </template>
    </div>
  </div>
</template>

<script>

import PopUpMapMixin from "@/mixins/PopUpMapMixin";import TableGeoNovation from "@/layout/GeoNovation/TableGeoNovation";

export default {
  name: "PopUpMapGeoNovation",
  components: {TableGeoNovation},
  mixins: [
    PopUpMapMixin,
  ]
}
</script>