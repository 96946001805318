<template>
    <autocomplete ref="autocompleteSearch" :search="search" @submit="onSubmit">

      <template
          #default="{
      rootProps,
      inputProps,
      inputListeners,
      resultListProps,
      resultListListeners,
      results,
      resultProps
    }"
      >
        <div class="autocomplete autocomplete__form" v-bind="rootProps" role="search">
          <input
              class="autocomplete__input form-control"
              type="text"
              v-bind="inputProps"
              v-on="inputListeners"
              id="gn-autocomplete-search"
              placeholder="Zoek in de lijst"
              autocomplete="off"
              name="gn-search-q"
              aria-controls="autocomplete-results"
              aria-autocomplete="both"
              aria-label="Zoekveld"
              aria-describedby="gn-autocomplete-help-text"
              ref="searchInput"
              @keyup="onkeyup"
          />

          <button v-if="keyUpValue !== ''" v-on:click="clear" class="autocomplete__search-button">
            <span class="sr-only">Maak de tekstbox voor het verfijnen van uw resultaat leeg</span>
            <span class="mdi mdi mdi-close" aria-hidden="true"></span>
          </button>

          <button v-if="keyUpValue === ''" class="autocomplete__search-button">
            <span class="sr-only">Maak de tekstbox voor het verfijnen van uw resultaat leeg</span>
            <span class="mdi mdi-magnify" aria-hidden="true"></span>
          </button>

          <ul class="autocomplete__result-list" aria-label="Zoekresultaten" role="listbox" v-bind="resultListProps" v-on="resultListListeners">
            <li class="autocomplete__section-header" aria-hidden="true">Zoekresultaten:</li>
            <li
                class="autocomplete__result-item"
                role="option"
                v-for="(result, index) in results"
                :key="resultProps[index].id"
                v-bind="resultProps[index]"
            >
              <a
                  @click="onClick"
                  :href="getUrl([
                       { name: 'q', value: result },
                     ])"
                  ref="autocompletelink" class="autocomplete__result-link" tabindex="-1">
                {{ result }}
              </a>
            </li>
          </ul>

          <span id="gn-autocomplete-help-text" class="sr-only">
            Als er zoekresultaten gevonden zijn kunt u de pijltjes toetsen omhoog en naar beneden gebruiken om een resultaat
            te selecteren. Toets enter om te navigeren naar het geselecteerde resultaat. Met de escape toets kunt u de
            invoer wissen.
          </span>
          <div id="gn-autocomplete-search-results-announcer" aria-live="assertive" aria-atomic="true" class="sr-only"></div>
        </div>
      </template>
    </autocomplete>
</template>

<script>
import SearchMixin from "@/mixins/SearchMixin";
import Autocomplete from '@trevoreyre/autocomplete-vue'

export default {
  name: "SearchNijmegen",
  components: {
    Autocomplete
  },
  mixins: [
    SearchMixin,
  ],
  mounted() {

    setInterval(() => {
        this.$refs.searchInput.removeAttribute('aria-expanded')
    })

    this.$refs.searchInput.removeAttribute('role');
  },
  methods: {
    onkeyup(result) {
      if(result.target.value === '') {
        this.$store.commit('setFilteredFeatures', this.features)
        this.searchValue = ''
        this.keyUpValue = ''
      } else {
        this.keyUpValue = result.target.value
      }

      if(result.keyCode === 13) {
        this.searchValue =  this.keyUpValue
      }

      this.$store.commit('setActiveTab', 'list')
      this.$store.commit('setActiveSubTab', '')

      if(this.$refs.autocompletelink !== undefined) {
        this.$refs.autocompletelink.map((element) => {
          if(
              element.parentElement.getAttribute('aria-selected') === 'true')
          {
            //console.log('make active')
            element.classList.value = ['autocomplete__result-link active']
          } else {
            element.classList.value = ['autocomplete__result-link']
          }
        });
      }
    },
  }
};
</script>

<style scoped>
  .autocomplete-result-list {
    z-index: 4001 !important;
  }
</style>


