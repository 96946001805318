<template>
  <div class="popover fade show">
    <h3 class="popover-header">
      <template v-for="settingItem in mapSettings.records[0].attributes[0]">
        {{feature.properties[settingItem.Attribute]}}
      </template>
    </h3>
    <div class="popover-body">
      <table class="gn-table">
        <caption class="gn-sr-only">
          Eigenschappen <template v-for="settingItem in mapSettings.records[0].attributes[0]">
          {{feature.properties[settingItem.Attribute]}}
        </template>
        </caption>
        <tbody>
        <template v-for="(setting, index) in mapSettings.records[0].attributes">
          <template v-if="index > 0">
            <template v-if="parseInt(mapSettings.ShowEmptyFormAttributes) === 1 ||
                            feature.properties[setting[0].Attribute] !== null && feature.properties[setting[0].Attribute] !== ''">
              <tr :key="setting[0].ID">
                <th>
                  <template v-if="parseInt(setting[0].Type) === 21 && feature.properties.kv.geometry === undefined">
                  </template>
                  <template v-else>
                    {{setting[0].DisplayName}}
                  </template>
                </th>
                <td>
                    <template v-for="settingItem in setting">
                        {{feature.properties[settingItem.Attribute]}}

                      <template v-if="parseInt(settingItem.Type) === 21 && feature.properties.kv.geometry !== undefined">
                             {{ (feature.properties.kv.geometry.value / 1000).toFixed(1) }} KM
                      </template>

                    </template>
                </td>
              </tr>
            </template>
          </template>
        </template>
        </tbody>
      </table>
      <p class="card-read-more text-right mb-0 text-uppercase">
        <a
            :href="getUrl([
                 { name: 'featureid', value: feature.id },
               ])"
            class="btnReadMore" :data-id="feature.id" :aria-label="generateAriaLabel(feature, mapSettings.records[0].attributes[0])">
          Lees meer
        </a>
      </p>
    </div>
  </div>
</template>

<script>

import PopUpMapMixin from "@/mixins/PopUpMapMixin";

export default {
  name: "PopUpMapNijmegen",
  mixins: [
    PopUpMapMixin,
  ]
}
</script>