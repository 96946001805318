<template>
  <div id="pills_list" class="pills" role="tablist" aria-label="Example Label" @keydown="clickKey">
    <div class="pills__item">
      <switch-component @eventname="setFocusTab"
                        v-bind:focus-tab="focusTab"
                        v-bind:data-index="'0'" v-bind:tab-index="''" v-bind:id="'list-1'" v-bind:tab-panel-id="'list-tab-1'"
          v-bind:class="'pills__button'" v-bind:name="'Lijst'" v-bind:element="'list'"/>
    </div>
    <div class="pills__item">
      <switch-component @eventname="setFocusTab"
                        v-bind:focus-tab="focusTab"
                        v-bind:data-index="'1'" v-bind:tab-index="'-1'" v-bind:id="'map-1'" v-bind:tab-panel-id="'map-tab-2'"
          v-bind:class="'pills__button'" v-bind:name="'Kaart'" v-bind:element="'map'"/>
    </div>
  </div>
</template>

<script>

import SwitchMixin from "@/mixins/SwitchMixin";

export default {
  name: "SwitchNijmegen",
  mixins: [
    SwitchMixin,
  ]
}
</script>