import axios from 'axios'
import authHeader from './auth-header'

const API_URL = '/admin/rest/geocomponentapi/bookmark/';

class FeatureService {
    getFeatures(kaartViewerUrl, bookmarkID, domainID, presentationID, queryParams) {

        //console.log(kaartViewerUrl + API_URL)

        return axios.get(kaartViewerUrl + API_URL + bookmarkID + '/domain/' + domainID + '/presentation/' + presentationID + '/map' + queryParams, {headers: authHeader()})
    }
}

export default new FeatureService()
