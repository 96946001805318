export const template = {
    state: {
        name: '',
    },
    getters: {
        getTemplateName: state => {
            return state.name
        },
    },
    mutations: {
        setTemplateName(state, name) {
            state.name = name
        },
    }
}