<template>
<!--  <ul class="pills" @keydown="clickKey">-->
<!--    <li class="pills__item" v-for="(layer) in settings.achtergrondkaarten" v-bind:key="layer.id">-->
<!--      <button class="pills__button" ref="layer-button" :aria-selected="visibleMap === layer.naam" v-on:click="setMap(layer.naam)" v-show="btnShowBackgroundLayer(layer)">-->
<!--        {{ layer.naam }}-->
<!--      </button>-->
<!--    </li>-->
<!--  </ul>-->
  <div @keydown="clickKey">
    <!-- Material unchecked -->
    <div class="form-check form-check-inline" v-for="(layer) in settings.achtergrondkaarten" v-bind:key="layer.id" v-show="btnShowBackgroundLayer(layer)" v-on:click="setMap(layer.naam)">
      <input type="radio" class="form-check-input" :id="'materialUnchecked-' + layer.id"
             name="materialExampleRadios"
             ref="layer-button"
             :aria-checked="'' + (visibleMap === layer.naam)"
             :checked="visibleMap === layer.naam">
      <label class="form-check-label" :for="'materialUnchecked-' + layer.id">{{ layer.naam }}</label>
    </div>
  </div>
</template>

<script>

import MapMixin from "@/mixins/MapMixin";

export default {
  name: "SwitchMapNijmegen",
  mixins: [
    MapMixin,
  ]
}
</script>
