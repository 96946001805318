<template>
  <div>
    <map-leaflet-map/>
  </div>
</template>

<script>
import MapLeafletMap from "./MapLeafletMap";

export default {
  name: "MapLeaflet",
  components: {
    MapLeafletMap
  }
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
@import '~leaflet-fullscreen/dist/leaflet.fullscreen.css';

html, body {
  height: 100%;
  margin: 0;
}

#map-leaflet-map {
  height: 500px;
  width: 100%
}
</style>



