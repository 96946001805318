import axios from 'axios'
import authHeader from './auth-header'

const API_URL = '/admin/rest/routeapi/';

class RouteService {
    getLocationReversed(kaartViewerUrl, lng, lat) {

        //console.log(kaartViewerUrl + API_URL)

        return axios.get(kaartViewerUrl + API_URL + 'location/lng/' + lng + '/lat/' + lat + '/reversed', {headers: authHeader()})
    }

    getLocation(kaartViewerUrl, query) {

        //console.log(kaartViewerUrl + API_URL)

        return axios.get(kaartViewerUrl + API_URL + 'location?cbsCodes=&query=' + query, {headers: authHeader()})
    }

    lookup(kaartViewerUrl, id) {

        //console.log(kaartViewerUrl + API_URL)

        return axios.get(kaartViewerUrl + API_URL + 'location/lookup/' + id, {headers: authHeader()})
    }
}

export default new RouteService()
