<template>
  <div v-if="listSettings.records !== undefined">
      <h2 v-if="searchValue !== ''">
        Zoekresultaten voor "<span v-html="searchValue"></span>"
      </h2>
      <h2 v-if="searchValue === ''">
        Alle zoekresultaten voor {{ listSettings.FormName }}
      </h2>
      <ul id="gn-list-results">
        <li v-if="!isFeatureFound">
          Er zijn geen {{ listSettings.FormName }} gevonden
        </li>
        <li
            v-for="feature in filteredFeatures.slice(activePage * itemsPerPage, activePage * itemsPerPage + itemsPerPage)"
            :key="feature.id">
          <h2 class="">
            <template v-if="detailSettings === undefined">
                <template v-for="settingItem in listSettings.records[0].attributes[0]">
                  {{ feature.properties[settingItem.Attribute] }}
                </template>
                <template v-for="settingItem2 in listSettings.records[0].attributes">
                  {{ feature.properties[settingItem2.Attribute] }}
                  <template v-for="settingItem in settingItem2">
                    <template v-if="parseInt(settingItem.Type) === 21">
                      <template v-if="feature.properties.kv.geometry !== undefined && !isNaN(feature.properties.kv.geometry.value)">
                        ({{ (feature.properties.kv.geometry.value / 1000).toFixed(1) }} KM)
                      </template>
                    </template>
                  </template>
                </template>
            </template>
            <template v-if="detailSettings !== undefined">
              <a class="" href="#" v-on:click="setActiveTab($event, 'list', feature, 'detail')">
                <template v-for="settingItem in listSettings.records[0].attributes[0]">
                  {{ feature.properties[settingItem.Attribute] }}
                </template>
                <template v-for="settingItem2 in listSettings.records[0].attributes">
                  {{ feature.properties[settingItem2.Attribute] }}
                  <template v-for="settingItem in settingItem2">
                    <template v-if="parseInt(settingItem.Type) === 21">
                      <template v-if="feature.properties.kv.geometry !== undefined && !isNaN(feature.properties.kv.geometry.value)">
                        ({{ (feature.properties.kv.geometry.value / 1000).toFixed(1) }} KM)
                      </template>
                    </template>
                  </template>
                </template>
              </a>
            </template>

          </h2>
          <table-geo-novation
              v-bind:feature="feature"
              v-bind:form-settings="listSettings"
              v-bind:type="'list'"
          ></table-geo-novation>
        </li>
      </ul>
      <nav aria-label="Pagina navigation">
        <ul class="gn-pagination">
          <li :class="{disabled:activePage === 0}">
            <button aria-disabled="true" v-on:click="goToPreviousPage($event)">
                <span aria-hidden="true" class="gn-icon gn-icon-left"></span>
                <span class="gn-sr-only">Vorige</span>
            </button>
          </li>
          <template v-for="n in pageNumbers">
            <li :class="{active:activePage === (n - 1)}" v-on:click="goToPage(n, $event)" v-if="n <= totalPageNumbers()"  v-bind:key="n">
              <button>
                {{ n }}
                <span class="gn-sr-only">(huidig)</span>
              </button>
            </li>
          </template>
          <li :class="{disabled:activePage === (totalPageNumbers() - 1)}">
            <button aria-disabled="false" v-on:click="goToNextPage($event)">
                <span aria-hidden="true" class="gn-icon gn-icon-right"></span>
                <span class="gn-sr-only">Volgende</span>
            </button>
          </li>
        </ul>
      </nav>
  </div>
</template>

<script>
import ListMixin from "@/mixins/ListMixin";
import PaginationMixin from "@/mixins/PaginationMixin";
import TableGeoNovation from "@/layout/GeoNovation/TableGeoNovation";

export default {
  name: "ListGeoNovation",
  components: {TableGeoNovation},
  mixins: [
      ListMixin,
      PaginationMixin
  ]
};
</script>


