import axios from 'axios'
import authHeader from './auth-header'

const API_URL = '/admin/rest/geocomponentapi/bookmark/';

class FeatureLinkedService {
    getFeatures(kaartViewerUrl, bookmarkID, domainID, presentationID, formID, queryParams) {

        //console.log(kaartViewerUrl + API_URL)

        return axios.get(kaartViewerUrl + API_URL + bookmarkID + '/domain/' + domainID + '/presentation/' + presentationID + '/info/' + formID + queryParams, {headers: authHeader()})
        // return axios.post(kaartViewerUrl + API_URL + bookmarkID + '/domain/' + domainID + '/presentation/' + presentationID + '/info/' + formID + queryParams, data, {headers: authHeader()})
    }

    postFeatures(kaartViewerUrl, bookmarkID, domainID, presentationID, formID, data) {

        //console.log(kaartViewerUrl + API_URL)

        return axios.post(kaartViewerUrl + API_URL + bookmarkID + '/domain/' + domainID + '/presentation/' + presentationID + '/info/' + formID, data, {headers: authHeader()})
        // return axios.post(kaartViewerUrl + API_URL + bookmarkID + '/domain/' + domainID + '/presentation/' + presentationID + '/info/' + formID + queryParams, data, {headers: authHeader()})
    }

    // admin/rest/geocomponentapi/bookmark/92/domain/5/presentation/297/info/wfs/HIS_MON_ONDERDEEL.37?nd=1617197493399&ts=1617197493394&json=
    getFeatureByFid(kaartViewerUrl, bookmarkID, domainID, presentationID, featureid) {

        //console.log(kaartViewerUrl + API_URL)

        return axios.get(kaartViewerUrl + API_URL + bookmarkID + '/domain/' + domainID + '/presentation/' + presentationID + '/info/wfs/' + featureid, {headers: authHeader()})
        // return axios.post(kaartViewerUrl + API_URL + bookmarkID + '/domain/' + domainID + '/presentation/' + presentationID + '/info/' + formID + queryParams, data, {headers: authHeader()})
    }
}

export default new FeatureLinkedService()
