<template>
    <div v-if="templateName !== ''">
        <fullscreen ref="fullscreen" @change="fullscreenChange">
            <layout-nijmegen v-if="templateName === 'nijmegen'"/>
            <layout-geo-novation v-if="templateName === 'geonovation'"/>
        </fullscreen>
    </div>
</template>

<script>
    import LayoutNijmegen from "@/layout/Nijmegen/LayoutNijmegen";
    import LayoutGeoNovation from "@/layout/GeoNovation/LayoutGeoNovation";
    import Fullscreen from "vue-fullscreen/src/component.vue"
    import UrlMixin from "@/mixins/UrlMixin";

    export default {
        mixins: [
          UrlMixin
        ],
        name: 'Layout',
        data() {
          return {
            fullscreen: false
          };
        },
        props: {
          template: String,
        },
        components: {
          LayoutNijmegen,
          LayoutGeoNovation,
          Fullscreen
        },
        computed: {
          templateName() {
            return this.$store.getters.getTemplateName
          },
          fullScreen() {
            return this.$store.getters.getFullScreen
          }
        },
        watch: {
          fullScreen() {
              // console.log(value)
              this.$refs['fullscreen'].toggle()
          }
        },
        methods: {
          fullscreenChange (fullscreen) {
            this.fullscreen = fullscreen
            this.$store.commit('setFullScreen', fullscreen)
          }
        }
    }
</script>

<style scoped>

</style>
