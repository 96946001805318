<template>
  <ul class="pills" @keydown="clickKey">
    <li class="pills__item" v-for="(layer) in settings.achtergrondkaarten" v-bind:key="layer.id">
      <button class="pills__button" ref="layer-button" :aria-selected="visibleMap === layer.naam" v-on:click="setMap(layer.naam)" v-show="btnShowBackgroundLayer(layer)">
        {{ layer.naam }}
      </button>
    </li>
  </ul>
</template>

<script>

import MapMixin from "@/mixins/MapMixin";

export default {
  name: "SwitchMapGeoNovation",
  mixins: [
    MapMixin,
  ]
}
</script>