import Vue from 'vue'
import Vuex from 'vuex'

import {feature} from './feature.module'
import {setting} from "./setting";
import {map} from "./map";
import {template} from "./template";
import {url} from "./url";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        feature,
        setting,
        map,
        template,
        url
    }
})
