var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"role":"search"},on:{"submit":_vm.handleFormSubmit}},[_c('autocomplete',{ref:"autocompleteSearch",attrs:{"search":_vm.search},on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var rootProps = ref.rootProps;
      var inputProps = ref.inputProps;
      var inputListeners = ref.inputListeners;
      var resultListProps = ref.resultListProps;
      var resultListListeners = ref.resultListListeners;
      var results = ref.results;
      var resultProps = ref.resultProps;
return [_c('div',_vm._b({staticClass:"autocomplete autocomplete__form"},'div',rootProps,false),[_c('label',{staticClass:"gn-sr-only",attrs:{"for":"gn-filterinput"}},[_vm._v("Verfijn je resultaat")]),_c('input',_vm._g(_vm._b({ref:"searchInput",staticClass:"gn-filterinput",attrs:{"type":"text","name":"q","id":"gn-filterinput","placeholder":"Zoek in de lijst","autocomplete":"off","aria-controls":"autocomplete-results","aria-autocomplete":"both","aria-label":"Zoekveld","aria-describedby":"autocomplete-help-text"},on:{"keyup":_vm.onkeyup}},'input',inputProps,false),inputListeners)),(_vm.keyUpValue !== '')?_c('button',{staticClass:"gn-filtersubmit-clear",on:{"click":_vm.clear}},[_c('span',{staticClass:"gn-text"},[_vm._v("Filter leegmaken")]),_c('span',{staticClass:"gn-icon gn-icon-filter-clear",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.keyUpValue === '')?_c('button',{staticClass:"gn-filtersubmit",on:{"click":_vm.clear}},[_c('span',{staticClass:"gn-text"},[_vm._v("Filter")]),_c('span',{staticClass:"gn-icon gn-icon-filter",attrs:{"aria-hidden":"true"}})]):_vm._e(),_c('ul',_vm._g(_vm._b({attrs:{"id":"gn-search-results","aria-label":"Zoekresultaten","role":"listbox"}},'ul',resultListProps,false),resultListListeners),[_c('li',{attrs:{"aria-hidden":"true"}},[_vm._v("Zoekresultaten:")]),_vm._l((results),function(result,index){return _c('li',_vm._b({key:resultProps[index].id,attrs:{"role":"option"}},'li',resultProps[index],false),[_c('a',{ref:"autocompletelink",refInFor:true,attrs:{"href":_vm.getUrl([
                         { name: 'q', value: result } ]),"tabindex":"-1"},on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(result)+" ")])])})],2),_c('span',{staticClass:"gn-sr-only",attrs:{"id":"autocomplete-help-text"}},[_vm._v(" Als er zoekresultaten gevonden zijn kunt u de pijltjes toetsen omhoog en naar beneden gebruiken om een resultaat te selecteren. Toets enter om te navigeren naar het geselecteerde resultaat. Met de escape toets kunt u de invoer wissen. ")]),_c('div',{staticClass:"gn-sr-only",attrs:{"id":"autocomplete-search-results-announcer","aria-live":"assertive","aria-atomic":"true"}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }