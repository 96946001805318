export const url = {
    state: {
        q: '',
        page: '',
        facetName: '',
        facetValue: '',
        location: '',
    },
    getters: {
        getUrlQ: state => {
            return state.q
        },
        getUrlPage: state => {
            return state.page;
        },
        getUrlFacetName: state => {
            return state.facetName
        },
        getUrlFaceValue: state => {
            return state.facetValue
        },
        getUrlLocation: state => {
            return state.location
        }
    },
    mutations: {
        setUrlQ(state, q) {
            state.q = q
        },
        setUrlPage(state, page) {
            state.page = page
        },
        setUrlFacetName(state, facetName) {
            state.facetName = facetName
        },
        setUrlFacetValue(state, facetValue) {
            state.facetValue = facetValue
        },
        setUrlLocation(state, location) {
            state.location = location
        }
    }
}