import axios from 'axios'
import authHeader from './auth-header'

class SettingService {
    getSettings(kaartViewerUrl, bookmarkName, bookmarkGroupName) {

        let url = kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + bookmarkName;
        if (bookmarkGroupName !== '') {
            url += '/category/' + bookmarkGroupName
        }

        return axios.get(url + '?ts=' + new Date().getTime(), {headers: authHeader()})
    }
}

export default new SettingService()
