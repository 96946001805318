
export default {
    props: {
        feature: {},
        formSettings: {},
        type: ''
    },
    computed: {
        kaartViewerUrl() {
            return this.$store.getters.getKaartViewerUrl
        },
        settings() {
            return this.$store.getters.getSettings
        }
    },
    methods: {
        isImage(extension, value) {

            // let isImage = false
            let isImage = false

            let extensionCheck = extension

            if (typeof value === 'string' && value.includes('.')) {
                extensionCheck = value.split('.')[1]
            } else if (typeof extension === 'string' && extension.includes('.')) {
                extensionCheck = extension.split('.')
                extensionCheck = extensionCheck[extensionCheck.length - 1]
            }

            if (extensionCheck === '' && extension === '') {
                isImage = true
            }

            switch (extensionCheck.replace('.', '')) {
                case 'jpg':
                case 'png':
                case 'jpeg':
                case 'gif':
                case 'ico':
                    isImage = true;
            }

            return isImage
        }
    }
}
